<template>
  <template v-if="dataReady && buildDetail">
    <basic-information :selectedBuild="buildDetail" @update:selectedBuild="updateSelectedBuild" />
  </template>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

import { definePageMeta } from '@/.nuxt/imports';
import BasicInformation from '@/components/build/build-detail/basic-information.vue';
import { useBuildStore } from '@/stores/build.store';
import type { Build } from '@/types/build/build.type';

definePageMeta({
  middleware: ['handle-product-data-middleware']
});

const buildStore = useBuildStore();
const { buildDetail } = storeToRefs(buildStore);
const dataReady = ref(false);

const updateSelectedBuild = (newBuild: Build) => {
  buildStore.buildDetail = newBuild;
};

onMounted(() => {
  dataReady.value = true;
});
</script>
