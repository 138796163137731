<template>
  <st-box class="mt-24">
    <div class="flex items-center gap-8">
      <h3 class="flex-1 text-2xl leading-xl break-all font-bold text-on-surface-elevation-2">
        DRM Maker
      </h3>
    </div>
    <div class="mt-8">
      <safe-html
        tag="p"
        class="text-sm font-regular leading-md text-on-surface-elevation-3"
        :html="$t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_msg1')"
      />
      <ul class="text-sm leading-md text-on-surface-elevation-3">
        <li
          class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_msg5') }}
        </li>
        <li
          class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_msg2') }}
        </li>
        <li
          class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_msg3') }}
        </li>
        <li
          class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_msg4') }}
        </li>
      </ul>
    </div>
    <st-form-title
      :formTitle="$t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_option_msg')"
      class="mt-16"
    />

    <radio-group
      v-model="build.applicability"
      name="build.applicability"
      :options="optionApply"
      rules="required"
      class="flex gap-24 pt-8"
      :optionProps="{
        size: 'sm',
        align: 'middle'
      }"
      :disabled="!canEditDRM"
      @update:modelValue="handleApplicabilityChange"
    />

    <template v-if="build.applicability === Confirmation.YES">
      <st-form-title
        :formTitle="
          $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_timeout_setting_title')
        "
        class="mt-16"
      />
      <div class="flex gap-8 items-center">
        <div class="w-[36rem]">
          <input-text
            :modelValue="build.verification?.toString()"
            name="build.verification"
            variant="outline"
            :placeholder="'1~999'"
            :type="InputTextTypes.Number"
            :min="1"
            :max="999"
            :unit="$t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_timeout_sec')"
            :disabled="!canEditDRM"
            @update:modelValue="(value) => handleVerificationChange(value)"
          />
        </div>
        <span class="text-md font-regular leading-sm">{{
          $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_timeout_sec')
        }}</span>
      </div>

      <st-form-title
        :formTitle="
          $t('studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_msg')
        "
        class="mt-16"
      />

      <p
        class="mt-4 text-md font-regular leading-sm text-on-surface-elevation-1"
      >
        {{ executionFile || '' }}
      </p>

      <div class="flex gap-16 items-center">
        <div class="w-[38rem]">
          <input-text
            v-model="build.applyFileDRM"
            name="build.applyFileDRM"
            variant="fill"
            :placeholder="
              $t(
                'studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_place_holder'
              )
            "
            :clearable="false"
            readonly
            :disabled="true"
          />
        </div>
        <s-button
          variant="secondary"
          size="sm"
          class="shrink-0"
          :disabled="!canEditDRM"
          @click="openFileDialog"
        >
          {{
            $t(
              'studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_select_file_btn'
            )
          }}
        </s-button>
      </div>
      <div class="mt-12 flex flex-col gap-8">
        <build-drm-message
          v-for="(count, status) in filteredDrmFilesCounts"
          :key="status"
          :buildMessageTitle="getStatusTitle(status)"
          :countFile="count"
        >
          <p
            v-for="file in drmStore.selectedDrmFilesByStatus[status]"
            :key="file.id"
            class="text-md font-regular leading-lg text-on-surface-elevation-2"
          >
            {{ file.relativePath }}
            <template v-if="status === APPLICATION_STATUS.FAILED">
              ({{
                $t(getErrorMessage(file.errorCode), getErrorMessageVariables(file.errorCode))
              }}
              [{{ file.errorCode }}])
            </template>
          </p>
        </build-drm-message>
      </div>
    </template>

    <s-button
      variant="primary"
      size="md"
      class="min-w-[11.6rem] mt-24"
      :disabled="!canSaveDRM"
      @click="onSaveDRM"
    >
      {{ $t('studio.common.popup_case_save_btn') }}
    </s-button>

    <drm-file-popup
      ref="drmFilePopupRef"
      :isOpen="isFileDialogOpen"
      :buildId="props.buildId"
      @close="closeFileDialog"
    />
  </st-box>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForm, useSetFieldValue } from 'vee-validate';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { updateBuildDRMMakerFilesApi } from '@/apis/build.api';
import { getBuildMetaReleaseInfoApi } from '@/apis/release-plan.api';
import BuildDrmMessage from '@/components/build/build-detail/build-drm-message.vue';
import DrmFilePopup from '@/components/build/build-detail/drm-file-popup.vue';
import ConfirmReleaseBuild from '@/components/build/dialog/confirm-release-build.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { useProduct } from '@/composables/useProduct';
import {
  APPLICATION_STATUS,
  DATA_VERSION,
  NUMBER_VERIFICATION_DEFAULT,
  RELEASE_STATUS_BUILD,
  TYPE_STATUS_DRM_SECURITY
} from '@/constants/build.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import { Confirmation, ProductVerifyStatus } from '@/enums/common.enum';
import { InputTextTypes } from '@/enums/validation.enum';
import { useBuildStore } from '@/stores/build.store';
import { useDRMStore } from '@/stores/drm-file.store';
import useProductStore from '@/stores/product.store';
import type {
  ApplicationStatus,
  BuildDrmDetail,
  DRMFile,
  StatusCounts,
  UpdateBuildDRMMakerParams
} from '@/types/build/build.type';
import type { FormOption } from '@/types/common/form.type';
import type { RunOptionsReleaseInfo } from '@/types/release-plan/release-plan.type';
import { getErrorMessage, getErrorMessageVariables } from '@/utils/build.util';
import { redirectTo } from '@/utils/common.util';

const props = defineProps<{
  buildId: string;
  drmInfo: BuildDrmDetail;
  releaseYn: string;
  releaseStatus: string | null;
  dataVersion: number;
  executionFile: string | null;
}>();

const { values } = useForm({
  initialValues: {
    build: {
      applicability: props.drmInfo.useDrm ? Confirmation.YES : Confirmation.NO,
      verification: props.drmInfo.drmVerifyTimeout || NUMBER_VERIFICATION_DEFAULT,
      applyFileDRM: ''
    }
  }
});

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const { t } = useI18n();
const drmStore = useDRMStore();
const buildStore = useBuildStore();
const { selectedDrmFilesCounts, drmFiles } = storeToRefs(drmStore);
const productStore = useProductStore();
const { product, isProductWritable } = storeToRefs(productStore);
const { buildDetail } = storeToRefs(buildStore);

const build = ref(values.build);
const isFileDialogOpen = ref(false);
const setApplicability = useSetFieldValue<string>('build.applicability');
const setApplyFileDRM = useSetFieldValue<string>('build.applyFileDRM');
const setVerification = useSetFieldValue<string>('build.verification');
const drmVerifyTimeout = computed(() => props.drmInfo.drmVerifyTimeout);
const executionFile = computed(() => props.executionFile);
const dataVersion = computed(() => props.dataVersion);

const canEditDRM = computed(() => {
  return isProductWritable.value && dataVersion.value !== DATA_VERSION.OLD;
});

const drmFilePopupRef = ref();

const getStatusTitle = (status: string) => {
  switch (status) {
    case APPLICATION_STATUS.REQUESTED:
      return 'studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_applying';
    case APPLICATION_STATUS.SUCCESS:
      return 'studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_applied';
    case APPLICATION_STATUS.FAILED:
      return 'studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_failed';
    case APPLICATION_STATUS.REQUESTED_DELETE:
      return 'studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_withdrawed';
    default:
      return '';
  }
};

const optionApply: FormOption[] = [
  {
    value: Confirmation.YES,
    label: t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_option_apply_yes_msg')
  },
  {
    value: Confirmation.NO,
    label: t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_option_apply_no_msg')
  }
];

const openFileDialog = () => {
  isFileDialogOpen.value = true;
};
const closeFileDialog = () => {
  isFileDialogOpen.value = false;
};

onMounted(async () => {
  updateDrmInfo();
  drmStore.setOriginalDrmFiles(props.drmInfo.drmFiles);

  const success = await drmStore.fetchDRMFiles({
    buildId: props.buildId
  });

  if (success && drmStore.drmFiles.length > 0) {
    drmStore.setOriginalSecurityLevels(drmStore.drmFiles);
    updateApplyFileDRM();
    drmStore.initPopupFiles();
  }
});

const handleApplicabilityChange = (value: Confirmation) => {
  if (value === Confirmation.YES) {
    const timeoutValue =
      drmVerifyTimeout.value?.toString() ?? NUMBER_VERIFICATION_DEFAULT.toString();
    build.value.verification = timeoutValue;
    setVerification(timeoutValue);
    setApplyFileDRM(convertText(drmStore.selectedDrmFileIds));
  } else {
    build.value.verification = '';
    setVerification('');
  }
};

const handleVerificationChange = (value: string) => {
  build.value.verification = value;
  setVerification(value);
};

const updateApplyFileDRM = () => {
  if (!drmFiles.value?.length) {
    return;
  }

  const selectedFiles = drmStore.selectedDrmFileIds
    .map((path: string) => drmFiles.value.find((file: DRMFile) => file.relativePath === path))
    .filter((file: DRMFile | undefined): file is DRMFile => file !== undefined)
    .map((file: DRMFile) => file.relativePath);

  showApplyFileDRM(selectedFiles);
};

const showApplyFileDRM = (files: string[]) => {
  if (files.length > 0) {
    const newValue = convertText(files);
    setApplyFileDRM(newValue);
  } else {
    setApplyFileDRM('');
  }
};

const convertText = (arr: string[]) => {
  if (!arr?.length) {
    return '';
  }
  const firstFile = arr[0];
  const othersCount = arr.length - 1;
  return othersCount > 0
    ? t('studio.file_upload.count', {
      fileName: firstFile,
      remainingFileNum: othersCount
    })
    : firstFile;
};

watch(() => drmStore.selectedDrmFileIds, updateApplyFileDRM, { deep: true });
watch(
  () => drmStore.originalDrmFiles,
  () => showApplyFileDRM(drmStore.originalDrmFiles),
  { deep: true }
);

const filteredDrmFilesCounts = computed<StatusCounts>(() => {
  return Object.entries(selectedDrmFilesCounts.value).reduce<StatusCounts>(
    (acc: StatusCounts, [status, count]: [string, number]) => {
      if (status !== APPLICATION_STATUS.NULL && count > 0) {
        acc[status as Exclude<ApplicationStatus, null>] = count;
      }
      return acc;
    },
    {}
  );
});

const originalValues = ref<{
  applicability: Confirmation;
  verification: string | number | null;
  applyFileDRM: string;
  securityLevels: Record<string, string>;
}>({
  applicability: props.drmInfo.useDrm ? Confirmation.YES : Confirmation.NO,
  verification: props.drmInfo.drmVerifyTimeout || NUMBER_VERIFICATION_DEFAULT,
  applyFileDRM: convertText(props.drmInfo.drmFiles) || '',
  securityLevels: {}
});

const hasChanges = computed(() => {
  const drmFilesChanged =
    drmStore.selectedDrmFileIds.length !== drmStore.originalDrmFiles.length ||
    drmStore.selectedDrmFileIds.some((path: string) => !drmStore.originalDrmFiles.includes(path)) ||
    drmStore.drmFiles.some((file: DRMFile) => {
      const originalLevel = drmStore.originalSecurityLevels[file.relativePath];
      return (
        drmStore.selectedDrmFileIds.includes(file.relativePath) &&
        file.securityLevel !== originalLevel
      );
    });

  // console.log('drmStore.originalSecurityLevels', drmStore.originalSecurityLevels);
  // console.log('drmStore.selectedDrmFileIds', drmStore.selectedDrmFileIds);
  // console.log('drmStore.drmFiles', drmStore.drmFiles);
  // console.log('drmStore.originalDrmFiles', drmStore.originalDrmFiles);

  return (
    build.value.applicability !== originalValues.value.applicability ||
    (build.value.applicability === Confirmation.YES &&
      build.value.verification !== originalValues.value.verification) ||
    (build.value.applicability === Confirmation.YES &&
      build.value.applyFileDRM !== originalValues.value.applyFileDRM) ||
    (build.value.applicability === Confirmation.YES && drmFilesChanged)
  );
});

const canSaveDRM = computed(() => {
  if (dataVersion.value === DATA_VERSION.OLD) {
    return false;
  }

  if (product.value?.verifyStatus === ProductVerifyStatus.START ||
    product.value?.verifyStatus === ProductVerifyStatus.REQUEST) {
    return false;
  }

  if (buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.RELEASE_UNDER_REVIEW) {
    return false;
  }

  if (!canEditDRM.value || !hasChanges.value) {
    return false;
  }

  if (build.value.applicability === Confirmation.YES) {
    const verificationNumber = Number(build.value.verification);
    const isValidVerification =
      !isNaN(verificationNumber) && verificationNumber >= 1 && verificationNumber <= 999;

    return isValidVerification;
  }

  return true;
});

const updateDrmInfo = () => {
  if (!buildStore.isRefreshFromPopup) {
    setApplyFileDRM(convertText(props.drmInfo.drmFiles));
    setApplicability(props.drmInfo.useDrm ? Confirmation.YES : Confirmation.NO);
    setVerification(props.drmInfo.drmVerifyTimeout?.toString() || NUMBER_VERIFICATION_DEFAULT);

    originalValues.value = {
      applicability: build.value.applicability,
      verification: build.value.verification,
      applyFileDRM: build.value.applyFileDRM || '',
      securityLevels: { ...drmStore.originalSecurityLevels }
    };
  } else {
    setApplyFileDRM(convertText(props.drmInfo.drmFiles));
    setApplicability(Confirmation.YES);
    setVerification(props.drmInfo.drmVerifyTimeout?.toString() || NUMBER_VERIFICATION_DEFAULT);

    originalValues.value = {
      applicability: props.drmInfo.useDrm ? Confirmation.YES : Confirmation.NO,
      verification: build.value.verification,
      applyFileDRM: build.value.applyFileDRM || '',
      securityLevels: { ...drmStore.originalSecurityLevels }
    };
  }
};

watch(
  () => props.drmInfo,
  (newDrmInfo: BuildDrmDetail) => {
    if (newDrmInfo) {
      drmStore.setOriginalDrmFiles(newDrmInfo.drmFiles);
      if (drmStore.drmFiles.length > 0) {
        drmStore.setOriginalSecurityLevels(drmStore.drmFiles);
      }
      updateDrmInfo();
    }
    buildStore.isRefreshFromPopup = false;
  },
  { deep: true, immediate: true }
);

const onSaveDRM = async () => {
  const isApplied = build.value.applicability === Confirmation.YES;

  /* eslint-disable indent */
  const formattedDrmFiles = isApplied
    ? drmStore.drmFiles
        .filter((file: DRMFile) => drmStore.selectedDrmFileIds.includes(file.relativePath))
        .map((file: DRMFile) => ({
          relativePath: file.relativePath,
          securityLevel: file.securityLevel || TYPE_STATUS_DRM_SECURITY.HIGH
        }))
    : [];
  /* eslint-enable indent */

  // **
  // https://jira.smilegate.net/browse/INDIA-19303
  // If isApplied is true, and formattedDrmFiles is empty
  // we have to change useDrm to false,  drmVerifyTimeout is null and drmApplyFiles is empty
  // **

  const shouldDisableDRM = isApplied && formattedDrmFiles.length === 0;

  const params = {
    buildId: props.buildId,
    useDrm: shouldDisableDRM ? false : isApplied,
    drmVerifyTimeout: shouldDisableDRM ? null : isApplied ? +build.value.verification : null,
    drmApplyFiles: formattedDrmFiles
  };

  await callAPISave(params);
};

const callAPISave = async (params: UpdateBuildDRMMakerParams) => {
  try {
    if (
      buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
      buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.APPLY_LIVE
    ) {
      const confirm = await showDialog({
        component: shallowRef(ConfirmReleaseBuild)
      });

      if (!confirm) {
        return;
      }
    }

    // Check Game Basic, game Demo, game DLC
    const currentProductType = productStore.currentProductDetailType;
    if (
      currentProductType === PRODUCT_TYPE_DETAIL.GAME_BASIC ||
      currentProductType === PRODUCT_TYPE_DETAIL.GAME_DEMO ||
      currentProductType === PRODUCT_TYPE_DETAIL.GAME_DLC
    ) {
      const buildMetaInfo = await getBuildMetaReleaseInfoApi(props.buildId);

      if (!buildMetaInfo) {
        return;
      }

      // If SDK is not applied
      if (!buildMetaInfo.isSdkApplied) {
        // Check run options directly
        if (!buildMetaInfo.runOptions || buildMetaInfo.runOptions.length === 0) {
          const result = await showConfirm({
            severity: 'info',
            content: t('studio.prj_prod.build_mgmt.build_detail.post_release_build_update.run_option_invalid_msg'),
            confirmLabel: t('studio.run_setting_pg_redirect_btn'),
            cancelLabel: t('studio.common.popup_case_close_btn'),
            cancelVariant: 'outline'
          });

          if (result) {
            redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, {
              open: {
                target: '_blank'
              }
            });
          }
        } else {
          // Check if any run option has exist or drmApplied as false
          const invalidRunOption = buildMetaInfo.runOptions.some(
            (option: RunOptionsReleaseInfo) => !option.exist
          );

          if (invalidRunOption) {
            const result = await showConfirm({
              severity: 'info',
              content: t('studio.build_run_option.exe_file_invalid_msg'),
              confirmLabel: t('studio.run_setting_pg_redirect_btn'),
              cancelLabel: t('studio.common.popup_case_close_btn'),
              cancelVariant: 'outline'
            });

            if (result) {
              redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, {
                open: {
                  target: '_blank'
                }
              });
            }
          }
        }
      }
    }

    const result = await updateBuildDRMMakerFilesApi(params);

    if (result?.code === 0) {
      drmFilePopupRef.value?.resetState();

      if (
        buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
        buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.APPLY_LIVE
      ) {
        const confirmDialogLive = await showAlert({
          severity: 'info',
          content: t('studio.common.popup_case_iv_release_guide'),
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
        if (confirmDialogLive) {
          await refreshDRMData();
        }
        return;
      }

      await refreshDRMData();
      if (!showCompleteSaveDialog()) {
        await refreshDRMData();
      }

      drmFilePopupRef.value?.resetState();
    } else {
      await showAlert({
        severity: 'info',
        content: t(COMMON_ERROR_MESSAGE_KEY),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!max-w-full !w-full'
      });
    }
  } catch (error) {}
};

const refreshDRMData = async () => {
  // Reset store
  drmStore.resetStore();

  // Fetch new DRM files
  await drmStore.fetchDRMFiles({
    buildId: props.buildId
  });

  // Get latest build detail
  await buildStore.getBuildDetail({
    buildId: props.buildId
  });

  // Update selected DRM file IDs
  drmStore.initPopupFiles();

  // Set original security levels after refresh
  if (drmStore.drmFiles.length > 0) {
    drmStore.setOriginalSecurityLevels(drmStore.drmFiles);
  }
};
</script>
