<template>
  <div>
    <span v-if="status === APPLICATION_STATUS.REQUESTED" class="text-xs font-regular">
      {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_applying') }}
    </span>
    <span v-else-if="status === APPLICATION_STATUS.SUCCESS" class="text-xs font-regular">
      {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_applied') }}
    </span>
    <span v-else-if="status === APPLICATION_STATUS.REQUESTED_DELETE" class="text-xs font-regular">
      {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_withdrawed') }}
    </span>
    <template v-else-if="status === APPLICATION_STATUS.FAILED">
      <s-contents-popup
        :distance="0"
        :offset="[0, 0]"
        placement="bottom-end"
        trigger="click"
        :minWidth="320"
        :flipOnUpdate="false"
      >
        <template #target>
          <button type="button" class="text-xs font-regular text-tint-red-a400 underline">
            {{ $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_files_failed') }}
          </button>
        </template>
        <template #contents>
          <s-contents-popup-content-body>
            <div class="flex flex-col gap-4">
              <p class="text-md font-regular leading-lg text-on-surface-elevation-2">
                {{ $t(errorMessage) }}
              </p>
              <span class="text-xs font-medium leading-xs text-system-red">
                [{{ errorCode }}]
              </span>
            </div>
          </s-contents-popup-content-body>
        </template>
      </s-contents-popup>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { APPLICATION_STATUS, VARIABLE_CODE_LANG } from '@/constants/build.const';
import type { ApplicationStatus } from '@/types/build/build.type';

const props = defineProps<{
  status: ApplicationStatus;
  errorCode?: number | null;
}>();

const errorMessage = computed(() => {
  if (!props.errorCode) {
    return '';
  }
  return VARIABLE_CODE_LANG[props.errorCode];
});
</script>
