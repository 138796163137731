<template>
  <!-- Banner -->
  <div
    v-if="isBannerVisible && selectedBuild?.dataVersion === DATA_VERSION.OLD"
    class="flex items-center px-[3rem] py-16 bg-[#FFE7E7] rounded-[2.8rem] mb-24"
  >
    <div class="flex-1 flex items-center gap-4 text-on-surface-elevation-3">
      <s-icon
        size="xl"
        icon="ic-v2-state-warning-circle-line"
        srOnlyText="Close"
        class="text-on-surface-elevation-3 self-start mt-[0.3rem]"
      />
      <safe-html
        tag="p"
        class="stds-text text-text4 leading-text4 font-text4 text-on-surface-elevation-3 font-medium"
        :html="$t('studio.drm_intergrity_check.not_available_for_v2_build_msg')"
      />
    </div>
    <button type="button" class="shrink-0 ml-8" @click="handleCloseBanner">
      <s-icon
        size="3xl"
        icon="ic-v2-control-close-line"
        srOnlyText="Close"
        class="text-on-surface-elevation-3"
      />
    </button>
  </div>
  <st-box>
    <div class="flex items-center gap-8">
      <h3 class="flex-1 text-2xl leading-xl break-all font-bold text-on-surface-elevation-2">
        {{ selectedBuild?.buildNo }}
      </h3>
      <div class="shrink-0 flex gap-16 items-center">
        <button
          type="button"
          class="text-on-surface-elevation-2 disabled:text-disabled-variant-1"
          :disabled="isRefreshing"
          @click="handleRefresh"
        >
          <s-icon icon="ic-v2-control-refresh-line" size="3xl" class="flex" srOnly="새로고침" />
        </button>
        <button
          type="button"
          :class="{
            'text-disabled-variant-1': !canEdit || isDeleteButtonDisabled(selectedBuild),
            'text-on-surface-elevation-2 hover:text-brand-primary':
              canEdit && !isDeleteButtonDisabled(selectedBuild)
          }"
          @click="handleDelete(selectedBuild, canEdit)"
        >
          <s-icon icon="ic-v2-object-delete-line" size="3xl" class="flex" srOnly="삭제" />
        </button>
      </div>
    </div>
    <div class="flex items-center flex-row gap-16 mt-12">
      <input-text
        v-model="detailForm"
        name="detailForm"
        :placeholder="$t('studio.build_mgmt.build_detail.description_place_holder')"
        maxLength="60"
        :disabled="!canEdit"
        countable
        :allowInputMaxLength="false"
        @input="handleInput"
      />
      <div class="shrink-0">
        <s-button
          variant="primary"
          size="sm"
          class="min-w-84 max-h-32 !rounded-[.8rem]"
          :class="{ 'cursor-not-allowed': !isSaveButtonEnabled }"
          :disabled="!isSaveButtonEnabled"
          @click="onUpdate"
        >
          {{ $t('studio.common.popup_case_save_btn') }}
        </s-button>
      </div>
    </div>
    <div
      class="mt-24 grid grid-cols-2 gap-y-20 gap-x-8 bg-[var(--stds-glob-color-gray20)] rounded-3xl py-24 px-[3rem]"
    >
      <st-folding-evaluate-item
        :titleValue="
          $t('studio.prj_prod.this_prod.product_data.build.details_basic_info_build_size_row3')
        "
        :dataValue="formattedReleaseSize || '-'"
        :noData="!formattedReleaseSize"
      />
      <st-folding-evaluate-item
        :titleValue="
          $t('studio.prj_prod.this_prod.product_data.build.details_basic_info_date_upload_row4')
        "
        :dataValue="formattedCreatedAt || '-'"
        :noData="!formattedCreatedAt"
      />
      <st-folding-evaluate-item
        :titleValue="
          $t('studio.prj_prod.this_prod.product_data.build.details_basic_info_release_status_row6')
        "
        :dataValue="formattedReleaseStatus || '-'"
        :noData="!formattedReleaseStatus"
      />
      <st-folding-evaluate-item
        :titleValue="
          $t('studio.prj_prod.this_prod.product_data.build.details_basic_info_modified_date_row5')
        "
        :dataValue="formattedModifiedAt || '-'"
        :noData="!formattedModifiedAt"
      />
      <build-detail-st-folding-evaluate-item
        class="col-span-2"
        :titleValue="
          $t('studio.prj_prod.this_prod.product_data.build.details_basic_info_upload_status_row7')
        "
      >
        <div class="mt-4">
          <span
            :class="[
              'rounded-[.6rem] inline-flex h-24 text-xs leading-xs items-center px-[.6rem] font-regular',
              statusConfig[uploadStatus].class
            ]"
          >
            {{ $t(statusConfig[uploadStatus].label) }}
          </span>
          <build-detail-upload-stopped
            v-if="
              uploadStatus === UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED ||
                uploadStatus === UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED
            "
            :uploadStatus="uploadStatus"
            :memberNo="selectedBuild?.memberNo"
            :selectedBuild="selectedBuild"
          />
          <build-detail-security-creation
            v-if="
              uploadStatus !== UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED &&
                uploadStatus !== UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED &&
                uploadStatus !== UPLOAD_STATUS_MESSAGE.IN_PROGRESS &&
                uploadStatus !== UPLOAD_STATUS_MESSAGE.SUCCESS
            "
            :uploadStatus="uploadStatus"
            :selectedBuild="selectedBuild"
            @refresh="handleRefresh"
          />
        </div>
      </build-detail-st-folding-evaluate-item>
    </div>
  </st-box>

  <drm-maker
    v-if="uploadStatus === UPLOAD_STATUS_MESSAGE.SUCCESS &&
      isDrmEnabled && selectedBuild?.drm"
    ref="drmMakerRef"
    :buildId="selectedBuild?.buildId"
    :drmInfo="selectedBuild?.drm"
    :releaseYn="selectedBuild?.releaseYn"
    :releaseStatus="selectedBuild?.releaseStatus"
    :dataVersion="selectedBuild?.dataVersion"
    :executionFile="selectedBuild?.executionFile"
  />
  <integrity-checks
    v-if="
      uploadStatus === UPLOAD_STATUS_MESSAGE.SUCCESS &&
        isIntegrityEnabled
    "
    ref="integrityChecksRef"
    :buildId="selectedBuild?.buildId"
    :integrityFiles="selectedBuild?.integrity"
    :releaseYn="selectedBuild?.releaseYn"
    :releaseStatus="selectedBuild?.releaseStatus"
    :dataVersion="selectedBuild?.dataVersion"
  />

  <div class="mt-40 flex justify-center gap-16">
    <s-button
      v-if="
        uploadStatus === UPLOAD_STATUS_MESSAGE.SUCCESS && isProductRelease && isExistBuildRelease
      "
      variant="primary"
      size="lg"
      class="!min-w-160"
      :disabled="!isDisable"
      @click="handleButtonClick"
    >
      {{ $t('studio.prj_prod.build_mgmt.build_detail.post_release_build_update_btn') }}
    </s-button>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useForm, useSetFieldValue } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  checkProductBuildReleaseStatusApi,
  getLatestLiveBuildApi,
  getProductBuildIdApi,
  updateProductBuildApi
} from '@/apis/build.api';
import { fetchRatingsProductsApi } from '@/apis/rating.api';
import { getBuildMetaReleaseInfoApi } from '@/apis/release-plan.api';
import BuildDetailStFoldingEvaluateItem from '@/components/build/build-detail/build-detail-st-folding-evaluate-item.vue';
import BuildDetailSecurityCreation from '@/components/build/build-detail/build-detail-upload-security-creation.vue';
import BuildDetailUploadStopped from '@/components/build/build-detail/build-detail-upload-stopped.vue';
import DrmMaker from '@/components/build/build-detail/drm-maker.vue';
import IntegrityChecks from '@/components/build/build-detail/integrity-checks.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFoldingEvaluateItem from '@/components/common/st-folding-evaluate-item.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { DATA_VERSION, RELEASE_STATUS_BUILD, STORAGE_KEY, UPLOAD_STATUS_MESSAGE } from '@/constants/build.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { PRODUCT_TYPE, PRODUCT_TYPE_DETAIL, type ProductType } from '@/constants/products.const';
import { PRODUCT_BUILD_PAGE_URL, PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import { ProductVerifyStatus } from '@/enums/common.enum';
import { RatingTypeStatus, VerifyStatus } from '@/enums/rating.enum';
import useProductStore from '@/stores/product.store';
import type {
  BannerVisibilityData,
  Build,
  UpdateProductBuildParams,
  UpdateProductBuildResponse
} from '@/types/build/build.type';
import type { RunOptionsReleaseInfo } from '@/types/release-plan/release-plan.type';
import {
  getUploadStatus,
  handleDelete,
  isDeleteButtonDisabled,
  releaseStatusBuild
} from '@/utils/build.util';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { formatFileSize } from '@/utils/file.util';

const { checkProductPermission } = useApp();

const { handleSubmit, setValues } = useForm({
  initialValues: {
    detailForm: ''
  }
});

const props = defineProps<{
  selectedBuild: Build;
}>();

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{ (e: 'update:selectedBuild', build: Build): void }>();

const route = useRoute();
const { t } = useI18n();
const productStore = useProductStore();
const { isProductWritable, productDetailLabel, product, currentProductDetailType } =
  storeToRefs(productStore);

const productId = route.params.productId as string;

const initialDescription = ref('');
const detailForm = ref('');
const isRefreshing = ref(false);
const isProductRelease = ref(false);
const isExistBuildRelease = ref(false);
const isCheckingLiveBuild = ref(false);
const latestLiveBuild = ref<Build | null>(null);
const integrityChecksRef = ref();
const drmMakerRef = ref();

const uploadStatus = computed(() => getUploadStatus(props.selectedBuild));

const setDetailForm = useSetFieldValue('detailForm');

const isDrmEnabled = computed(() => {
  const allowedTypes: ProductType[] = [PRODUCT_TYPE.GAME, PRODUCT_TYPE.DEMO, PRODUCT_TYPE.UTILITY];
  return allowedTypes.includes(productDetailLabel.value as ProductType);
});

const isIntegrityEnabled = computed(() => {
  const allowedTypes: ProductType[] = [
    PRODUCT_TYPE.GAME,
    PRODUCT_TYPE.DEMO,
    PRODUCT_TYPE.UTILITY,
    PRODUCT_TYPE.DLC
  ];
  return allowedTypes.includes(productDetailLabel.value as ProductType);
});

const canEdit = computed(() => {
  return isProductWritable.value;
});

const statusConfig = {
  [UPLOAD_STATUS_MESSAGE.IN_PROGRESS]: {
    label: 'studio.build_mgmt.build_upload_stt0_in_progress',
    class: ''
  },
  [UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED]: {
    label: 'studio.build_mgmt.build_upload_stt1_paused',
    class: 'bg-[#F0F2F8] text-[#909298]'
  },
  [UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED]: {
    label: 'studio.build_mgmt.build_upload_stt2_upload_failed',
    class: 'bg-[#FFEDED] text-[#E84C4C]'
  },
  [UPLOAD_STATUS_MESSAGE.REVIEWING]: {
    label: 'studio.build_mgmt.build_upload_stt3_inspecting',
    class: 'bg-neutral-variant-2 text-[#5F94BA]'
  },
  [UPLOAD_STATUS_MESSAGE.BUILD_CREATION_FAILED]: {
    label: 'studio.build_mgmt.build_upload_stt4_build_gen_failed',
    class: 'bg-[#FFEDED] text-[#E84C4C]'
  },
  [UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_FAILED]: {
    label: 'studio.build_mgmt.build_upload_stt5_security_inspection_failed',
    class: 'bg-[#FFEDED] text-[#E84C4C]'
  },
  [UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_REVIEWING]: {
    label: 'studio.build_mgmt.build_upload_stt5_security_inspection_failed',
    class: 'bg-[#FFEDED] text-[#E84C4C]'
  },
  [UPLOAD_STATUS_MESSAGE.FAILURE]: {
    label: 'studio.build_mgmt.build_upload_stt6_failed',
    class: 'bg-[#FFEDED] text-[#E84C4C]'
  },
  [UPLOAD_STATUS_MESSAGE.SUCCESS]: {
    label: 'studio.build_mgmt.build_upload_stt7_completed',
    class: 'bg-success-container text-on-success-container'
  }
};

const formattedReleaseSize = computed(() => {
  return formatFileSize(props.selectedBuild.releaseSize);
});

const formattedCreatedAt = computed(() => {
  return getDateTimeByLocale(props.selectedBuild.createdAt);
});

const formattedModifiedAt = computed(() => {
  return getDateTimeByLocale(props.selectedBuild.modifiedAt);
});

const formattedReleaseStatus = computed(() => {
  return releaseStatusBuild(props.selectedBuild?.releaseStatus) || '-';
});

watch(
  () => props.selectedBuild,
  (newBuild: Build) => {
    if (newBuild) {
      const description = newBuild.buildDescription || '';
      detailForm.value = description;
      initialDescription.value = description;
      setValues({ detailForm: description });
    }
  },
  { immediate: true }
);

const isSaveButtonEnabled = computed(() => {
  if (!canEdit.value) {
    return false;
  }

  const trimmedDetailForm = detailForm.value.trim();
  return trimmedDetailForm !== '' && trimmedDetailForm !== initialDescription.value;
});

watch(detailForm, (newValue: string) => {
  setValues({ detailForm: newValue });
});

const onUpdate = handleSubmit(async () => {
  const params: UpdateProductBuildParams = {
    buildId: props.selectedBuild.buildId,
    buildDescription: detailForm.value.trim()
  };

  const response: UpdateProductBuildResponse | undefined = await updateProductBuildApi(params);
  if (!response) {
    return;
  }

  if (response.code === 0) {
    await showAlert({
      severity: 'info',
      content: t('studio.prj_prod_mngmt.prod_home.prod_terms_detail.term_saved_popup'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  } else {
    await showAlert({
      severity: 'info',
      content: t(COMMON_ERROR_MESSAGE_KEY),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  }
});

const handleRefresh = async () => {
  if (isRefreshing.value) {
    return;
  }

  try {
    isRefreshing.value = true;
    const params = {
      buildId: props.selectedBuild.buildId
    };
    const refreshedBuild = await getProductBuildIdApi(params);

    if (refreshedBuild && typeof refreshedBuild !== 'string') {
      emit('update:selectedBuild', refreshedBuild);

      integrityChecksRef.value?.integrityFilePopupRef?.value?.resetState();
      drmMakerRef.value?.drmFilePopupRef?.value?.resetState();
    } else {
      throw new Error('Invalid response from API');
    }
  } catch (error) {
    await showAlert({
      severity: 'info',
      content: t(COMMON_ERROR_MESSAGE_KEY),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  } finally {
    isRefreshing.value = false;
  }
};

const canUpdateBasedOnLiveBuild = computed(() => {
  if (!props.selectedBuild) {
    return false;
  }

  if (!latestLiveBuild.value) {
    return true;
  }

  return +props.selectedBuild.buildNo > +latestLiveBuild.value.buildNo;
});

const isDisable = computed(() => {
  const { uploadStatus, releaseStatus, dataVersion } = props.selectedBuild;

  // Disable button if dataVersion is 1
  if (dataVersion === DATA_VERSION.OLD) {
    return false;
  }

  // Check condition enable button:
  // 1. Upload status must be SUCCESS
  // 2. Release status must be not in [VERIFY_REVIEW_REJECTED, RELEASE_UNDER_REVIEW, APPLY_LIVE, END]
  // 3. isProductRelease and isExistBuildRelease must be true
  // 4. Can't update build if build No number lower than build No number of build has been APPLY_LIVE
  // 5. Product verification status must not be START or REQUEST
  return (
    uploadStatus === UPLOAD_STATUS_MESSAGE.SUCCESS &&
    isProductRelease.value &&
    isExistBuildRelease.value &&
    canUpdateBasedOnLiveBuild.value &&
    !(product.value?.verifyStatus === ProductVerifyStatus.START ||
      product.value?.verifyStatus === ProductVerifyStatus.REQUEST) &&
    (releaseStatus === null ||
      ![
        RELEASE_STATUS_BUILD.VERIFY_REVIEW_REJECTED,
        RELEASE_STATUS_BUILD.RELEASE_UNDER_REVIEW,
        RELEASE_STATUS_BUILD.APPLY_LIVE,
        RELEASE_STATUS_BUILD.END
      ].includes(releaseStatus))
  );
});

const onUpdatingBuilds = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  redirectTo(`${PRODUCT_BUILD_PAGE_URL}/${props.selectedBuild.buildId}/live`);
};

const handleButtonClick = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  // Only check run options for GAME_BASIC and GAME_DEMO
  if (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO
  ) {
    // https://jira.smilegate.net/browse/INDIA-21158
    const checkRating = await fetchRatingsProductsApi(productId);

    // Check if rating revision is in progress
    if (checkRating &&
          checkRating.requestRating &&
          checkRating.requestRating.type === RatingTypeStatus.GAME_REVISION &&
          checkRating.requestRating.verifyInfo &&
          checkRating.requestRating.verifyInfo.verifyStatus === VerifyStatus.REQUEST) {
      await showAlert({
        content: t('studio.build_rating_review.revision_already_in_progress_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
      return;
    }

    const buildMetaInfo = await getBuildMetaReleaseInfoApi(props.selectedBuild.buildId);

    if (!buildMetaInfo) {
      return;
    }

    // If SDK is applied, proceed normally
    if (buildMetaInfo.isSdkApplied) {
      await onUpdatingBuilds();
      return;
    }

    // If SDK is not applied and DRM is not used, show confirmation
    // if (!buildMetaInfo.isUseDrm) {
    //   const result = await showConfirm({
    //     severity: 'info',
    //     content: t(
    //       'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.run_option_invalid_msg'
    //     ),
    //     confirmLabel: t('studio.run_setting_pg_redirect_btn'),
    //     cancelLabel: t('studio.common.popup_case_close_btn'),
    //     cancelVariant: 'outline'
    //   });

    //   if (result) {
    //     redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, {
    //       open: {
    //         target: '_blank'
    //       }
    //     });
    //   }
    //   return;
    // }

    // If run options are empty, show confirmation
    if (!buildMetaInfo.runOptions || buildMetaInfo.runOptions.length === 0) {
      const result = await showConfirm({
        severity: 'info',
        content: t('studio.prj_prod.build_mgmt.build_detail.post_release_build_update.run_option_invalid_msg'),
        confirmLabel: t('studio.run_setting_pg_redirect_btn'),
        cancelLabel: t('studio.common.popup_case_close_btn'),
        cancelVariant: 'outline'
      });

      if (result) {
        redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, {
          open: {
            target: '_blank'
          }
        });
      }
    } else {
      // Check if all run options have both exist and drmApplied as true
      const invalidRunOption = buildMetaInfo.runOptions.some(
        (option: RunOptionsReleaseInfo) => !option.exist
      );

      if (invalidRunOption) {
        const result = await showConfirm({
          severity: 'info',
          content: t('studio.build_run_option.exe_file_invalid_msg'),
          confirmLabel: t('studio.run_setting_pg_redirect_btn'),
          cancelLabel: t('studio.common.popup_case_close_btn'),
          cancelVariant: 'outline'
        });

        if (result) {
          redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL, {
            open: {
              target: '_blank'
            }
          });
        }
      }
    }

    // If all validations pass, proceed normally
    await onUpdatingBuilds();
  } else {
    // For all other types, proceed normally
    await onUpdatingBuilds();
  }
};

const fetchBuildReleaseStatus = async () => {
  try {
    if (!productId) {
      return;
    }

    const response = await checkProductBuildReleaseStatusApi(productId);
    if (!response) {
      return;
    }
    isProductRelease.value = response.isProductRelease;
    isExistBuildRelease.value = response.isExistBuildRelease;
  } catch (error) {}
};

const handleInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  detailForm.value = input.value;
  setDetailForm(input.value);
};

const fetchLatestLiveBuild = async () => {
  if (isCheckingLiveBuild.value) {
    return;
  }

  const gameId = product.value?.gameId;
  if (!gameId) {
    return;
  }

  try {
    isCheckingLiveBuild.value = true;
    const data = await getLatestLiveBuildApi(gameId);
    if (data && typeof data !== 'string') {
      latestLiveBuild.value = data;
    } else {
      latestLiveBuild.value = null;
    }
  } catch (error) {
    latestLiveBuild.value = null;
  } finally {
    isCheckingLiveBuild.value = false;
  }
};

watch(
  () => product.value?.gameId,
  (newGameId: string | undefined) => {
    if (newGameId) {
      fetchLatestLiveBuild();
    }
  }
);

const init = async () => {
  await Promise.all([fetchBuildReleaseStatus(), fetchLatestLiveBuild()]);
};

init();

/**
 * Banner Visibility Management
 *
 * This code manages the visibility state of a warning banner based on user interaction.
 * When the user closes the banner, the state is stored in localStorage using buildId as the key.
 * This mechanism ensures that users do not see the same warning repeatedly when revisiting
 * the build details page after dismissing the notification.
 *
 * Workflow:
 * 1. By default, the banner is visible (isBannerVisible = true).
 * 2. When the component is mounted or buildId changes, checkBannerVisibility() checks localStorage.
 * 3. When the user clicks close, handleCloseBanner() saves the state to localStorage and hides the banner.
 */
const isBannerVisible = ref(true);

const getBannerDataFromStorage = (): BannerVisibilityData => {
  try {
    const storedData = localStorage.getItem(STORAGE_KEY);
    return storedData ? JSON.parse(storedData) as BannerVisibilityData : {};
  } catch (e) {
    console.error('Error parsing banner data from localStorage', e);
    return {};
  }
};

const handleCloseBanner = () => {
  if (!props.selectedBuild?.buildId) {
    return;
  }

  const bannerData = getBannerDataFromStorage();
  bannerData[props.selectedBuild.buildId] = true;

  localStorage.setItem(STORAGE_KEY, JSON.stringify(bannerData));

  isBannerVisible.value = false;
};

const checkBannerVisibility = () => {
  if (!props.selectedBuild?.buildId) {
    return;
  }

  const bannerData = getBannerDataFromStorage();
  if (bannerData[props.selectedBuild.buildId]) {
    isBannerVisible.value = false;
  }
};

watch(
  () => props.selectedBuild?.buildId,
  (newBuildId: string | undefined) => {
    if (newBuildId) {
      checkBannerVisibility();
    } else {
      isBannerVisible.value = true;
    }
  },
  { immediate: true }
);
</script>
