<template>
  <s-dialog to="stds-dialog-overview" size="md" :open="isOpen">
    <s-dialog-overlay />
    <s-dialog-panel>
      <st-dialog-header class="flex-1 shrink-0" @clickClose="onClose">
        <span class="font-bold">{{
          $t('studio.prj_prod.this_prod.product_data.build.details_drm_maker_select_file_pop_title')
        }}</span>
      </st-dialog-header>
      <s-dialog-content class="flex-1 !px-0 overflow-hidden">
        <s-dialog-content-body class="h-full flex flex-col">
          <div class="shrink-0 flex items-center gap-40 px-28 mb-16">
            <div class="flex items-center gap-8 shrink-0">
              <span class="text-md font-bold text-on-surface-elevation-2 leading-sm">{{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.details_select_file_pop_total_file'
                )
              }}</span>
              <span class="text-sm leading-xl text-on-surface-elevation-4">
                <span class="text-on-surface-elevation-2 font-bold">{{ selectedCount }}</span>
                / {{ totalElements }}
              </span>
            </div>
            <button
              type="button"
              class="shrink-0 ml-auto text-on-surface-elevation-2 disabled:text-disabled-variant-1"
              @click="onRefresh"
            >
              <s-icon icon="ic-v2-control-refresh-line" size="3xl" class="flex" srOnly="새로고침" />
            </button>
          </div>
          <div
            v-end-scroll="onLoadMore"
            class="relative flex-1 studio-scrollbar-4 pl-28 pr-8 min-h-300"
            :style="{ maxHeight: getMaxHeight }"
          >
            <m-table>
              <colgroup>
                <col style="width: 4.8rem" />
                <col style="width: 9.6rem" />
                <col style="width: auto" />
                <col style="width: 14.5rem" />
              </colgroup>
              <thead>
                <tr>
                  <st-th
                    v-model:checkBoxValue="checkAll"
                    checkboxUse
                    class="sticky top-0 z-[601]"
                    @onCheckboxUpdate="toggleSelectAll"
                  />
                  <st-th
                    :thTitle="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_select_file_security_standard_header2'
                      )
                    "
                    class="sticky top-0 z-[601]"
                    :tooltipUse="true"
                    :isShowIcon="true"
                    :tooltipText="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_select_file_security_standard_tooltip'
                      )
                    "
                    tooltipPlacement="bottom-start"
                    :offset="[-8, 0]"
                  />
                  <st-th
                    :sortingUse="!isInteracted"
                    sortingDefault
                    :thTitle="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_select_file_pop_filename_header2'
                      )
                    "
                    class="sticky top-0 z-[601]"
                    :tooltipUse="true"
                    :sortValue="drmStore.getSortValue(SORT_BY_MAKER.RELATIVE_PATH)"
                    @onSort="(value) => handleSort(SORT_BY_MAKER.RELATIVE_PATH, value)"
                  />
                  <st-th
                    :sortingUse="!isInteracted"
                    sortingDefault
                    :thTitle="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_drm_maker_select_file_pop_status_header3'
                      )
                    "
                    class="sticky top-0 z-[601]"
                    :tooltipUse="true"
                    :sortValue="drmStore.getSortValue(SORT_BY_MAKER.APPLICATION_STATUS)"
                    @onSort="(value) => handleSort(SORT_BY_MAKER.APPLICATION_STATUS, value)"
                  />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in localDrmFiles"
                  :key="item.relativePath"
                  class="border-b-1 border-solid border-disabled-variant-3"
                >
                  <st-td aCenter class="!py-[.5rem]">
                    <checkbox
                      :modelValue="item.checked"
                      :options="{
                        size: 'sm',
                        align: 'middle',
                        class: 'h-16 w-16'
                      }"
                      @update:modelValue="(value) => updateCheckAll(item.relativePath, value)"
                    />
                  </st-td>
                  <st-td aCenter class="!py-[.5rem] !px-8">
                    <div class="drm-dropdown-custom">
                      <s-dropdown
                        size="sm"
                        variant="line"
                        :distance="0"
                        :offset="[0, 0]"
                        :closeOnClick="true"
                        :interactive="true"
                        appendTo="parent"
                        placement="bottom-start"
                        class="w-full"
                        :isDisabled="!item.checked"
                        :modelValue="item.securityLevel || TYPE_STATUS_DRM_SECURITY.HIGH"
                        @update:modelValue="
                          (value) => updateSecurityLevel(item.relativePath, value)
                        "
                      >
                        <s-dropdown-button class="w-full">
                          {{ getSecurityLevelDisplay(item) }}
                        </s-dropdown-button>
                        <template #dropdownItems>
                          <s-dropdown-item
                            v-for="level in [
                              TYPE_STATUS_DRM_SECURITY.HIGH,
                              TYPE_STATUS_DRM_SECURITY.NORMAL
                            ]"
                            :key="level"
                            :value="level"
                            :selected="item.securityLevel === level"
                          >
                            {{ $t(SECURITY_LEVEL_DISPLAY[level]) }}
                          </s-dropdown-item>
                        </template>
                      </s-dropdown>
                    </div>
                  </st-td>
                  <st-conditional-tooltip-td
                    aLeft
                    :tdValue="item.relativePath"
                    class="!py-[.5rem] cursor-pointer"
                    textClass="text-sm truncate block"
                    @click="() => handleCellClick(item)"
                  />
                  <st-td aLeft class="!py-[.5rem]">
                    <application-status-cell
                      :status="item.applicationStatus"
                      :errorCode="item.errorCode"
                    />
                  </st-td>
                </tr>
              </tbody>
            </m-table>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="shrink-0">
        <s-button class="sm:min-w-264" @click="onSave">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="stds-dialog-overview" />
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ApplicationStatusCell from '@/components/build/build-detail/application-status-cell.vue';
import StConditionalTooltipTd from '@/components/common/st-conditional-tooltip-td.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import {
  SECURITY_LEVEL_DISPLAY,
  SORT_BY_MAKER,
  TYPE_STATUS_DRM_SECURITY
} from '@/constants/build.const';
import { useBuildStore } from '@/stores/build.store';
import { useDRMStore } from '@/stores/drm-file.store';
import type { DRMFile, DRMFileParams } from '@/types/build/build.type';

const props = defineProps<{
  isOpen: boolean;
  buildId: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const drmStore = useDRMStore();
const tempSelectedIds = ref<string[]>([]);
const buildStore = useBuildStore();
const windowHeight = ref(window.innerHeight);
const isInteracted = ref(false);
const hasSavedBefore = ref(false);

watch(
  () => props.isOpen,
  (newValue: boolean) => {
    if (newValue) {
      tempSelectedIds.value = [...drmStore.selectedDrmFileIds];
      drmStore.isSelectAll = false;
    }
  }
);

watch(
  () => drmStore.popupDrmFiles,
  () => {
    if (drmStore.isSelectAll) {
      tempSelectedIds.value = drmStore.popupDrmFiles.map((file: DRMFile) => file.relativePath);
    }
  }
);

const localDrmFiles = computed(() => {
  return [...drmStore.popupDrmFiles].map((file: DRMFile) => ({
    ...file,
    checked: tempSelectedIds.value.includes(file.relativePath),
    errorCode: file.errorCode,
    securityLevel: file.securityLevel || TYPE_STATUS_DRM_SECURITY.NONE
  }));
});

const totalElements = computed(() => drmStore.totalElements);

const checkAll = computed(
  () => localDrmFiles.value.length > 0 && localDrmFiles.value.every((file: DRMFile) => file.checked)
);

const selectedCount = computed(() => {
  return localDrmFiles.value.filter((file: DRMFile) => file.checked).length;
});

const toggleSelectAll = (value: boolean) => {
  isInteracted.value = true;
  drmStore.isSelectAll = value;
  if (value) {
    tempSelectedIds.value = drmStore.popupDrmFiles.map((file: DRMFile) => file.relativePath);

    drmStore.popupDrmFiles.forEach((file: DRMFile) => {
      file.securityLevel = TYPE_STATUS_DRM_SECURITY.HIGH;
    });
  } else {
    tempSelectedIds.value = [];

    drmStore.popupDrmFiles.forEach((file: DRMFile) => {
      file.securityLevel = TYPE_STATUS_DRM_SECURITY.NONE;
    });
  }
};

const updateCheckAll = (relativePath: string, isChecked: boolean) => {
  isInteracted.value = true;
  if (isChecked) {
    tempSelectedIds.value.push(relativePath);

    const fileIndex = drmStore.popupDrmFiles.findIndex(
      (file: DRMFile) => file.relativePath === relativePath
    );
    if (fileIndex !== -1) {
      drmStore.popupDrmFiles[fileIndex].securityLevel = TYPE_STATUS_DRM_SECURITY.HIGH;
    }
  } else {
    tempSelectedIds.value = tempSelectedIds.value.filter(
      (nameRelativePath: string) => nameRelativePath !== relativePath
    );
    const fileIndex = drmStore.popupDrmFiles.findIndex(
      (file: DRMFile) => file.relativePath === relativePath
    );
    if (fileIndex !== -1) {
      drmStore.popupDrmFiles[fileIndex].securityLevel = TYPE_STATUS_DRM_SECURITY.NONE;
    }
  }
};

const resetToOriginal = () => {
  tempSelectedIds.value = [...drmStore.selectedDrmFileIds];
  drmStore.resetSecurityLevels();
};

const onClose = () => {
  if (!hasSavedBefore.value) {
    resetState();
  }
  resetToOriginal();
  emit('close');
};

const onSave = () => {
  hasSavedBefore.value = true;
  drmStore.applyPopupChanges();
  drmStore.updateSelectedDrmFileIds(tempSelectedIds.value);
  emit('close');
};

const handleSort = (field: string, value: number) => {
  const direction = drmStore.convertSortValueToDirection(value);
  drmStore.updateSort(field, direction);
  resetAndFetchData();
};

const resetAndFetchData = () => {
  drmStore.resetDRMFiles();
  fetchDRMFiles();
  drmStore.updatePopupFiles();
};

const fetchDRMFiles = async () => {
  const params: DRMFileParams = {
    buildId: props.buildId
  };
  await drmStore.fetchDRMFiles(params);
};

const onLoadMore = async () => {
  if (!drmStore.isLastPage) {
    drmStore.currentPage++;
    await fetchDRMFiles();
  }
};

const onRefresh = async () => {
  resetState();

  drmStore.resetStore();
  tempSelectedIds.value = [];
  await fetchDRMFiles();

  // Call build detail store to refresh the build detail
  buildStore.isRefreshFromPopup = true;
  await buildStore.getBuildDetail({
    buildId: props.buildId
  });
  drmStore.initPopupFiles();

  tempSelectedIds.value = [...drmStore.selectedDrmFileIds];
};

const updateSecurityLevel = (relativePath: string, level: string) => {
  const fileIndex = drmStore.popupDrmFiles.findIndex(
    (file: DRMFile) => file.relativePath === relativePath
  );
  if (fileIndex !== -1) {
    drmStore.popupDrmFiles[fileIndex].securityLevel = level;
  }
};

const getSecurityLevelDisplay = (file: DRMFile) => {
  if (!file.checked) {
    return '-';
  }

  if (file.securityLevel === TYPE_STATUS_DRM_SECURITY.NONE) {
    return t(SECURITY_LEVEL_DISPLAY.HIGH);
  }

  return t(SECURITY_LEVEL_DISPLAY[file.securityLevel as keyof typeof SECURITY_LEVEL_DISPLAY]);
};

const getMaxHeight = computed(() => {
  return windowHeight.value >= 900 ? '64.75rem' : '47.75rem';
});

const handleResize = () => {
  windowHeight.value = window.innerHeight;
};

const handleCellClick = (file: DRMFile) => {
  isInteracted.value = true;
  const newCheckedState = !file.checked;
  updateCheckAll(file.relativePath, newCheckedState);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const resetState = () => {
  isInteracted.value = false;
  hasSavedBefore.value = false;
};

defineExpose({
  resetState
});
</script>

<style scoped>
.drm-dropdown-custom {
  position: relative;
}

.drm-dropdown-custom :deep(.stds-dropdown-list-item .stove-ui-icon) {
  display: none;
}

.drm-dropdown-custom :deep(.stds-dropdown-list-item) {
  padding-left: 16px;
}

.drm-dropdown-custom :deep(li[selected='true'] > .stds-dropdown-list-item) {
  color: var(--stds-sem-color-brand-primary);
  font-weight: 500;
}

.drm-dropdown-custom :deep(li[selected='false'] > .stds-dropdown-list-item) {
  font-weight: normal;
  color: inherit;
}
</style>
