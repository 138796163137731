<template>
  <s-dialog to="stds-dialog-overview" size="md" :open="isOpen">
    <s-dialog-overlay />
    <s-dialog-panel class="relative">
      <div
        v-if="isSelectAllLoading"
        class="bg-black/6 absolute w-full h-full z-[3001] pointer-events-none"
      ></div>
      <st-dialog-header class="flex-1 shrink-0" @clickClose="onClose">
        <span class="font-bold">
          {{
            $t(
              'studio.prj_prod.this_prod.product_data.build.details_integrity_check_select_file_pop_title'
            )
          }}
        </span>
      </st-dialog-header>
      <s-dialog-content class="flex-1 !px-0 overflow-hidden">
        <s-dialog-content-body class="h-full flex flex-col">
          <div class="shrink-0 flex items-center gap-40 px-28 mb-16">
            <div class="flex items-center gap-8 shrink-0">
              <span class="text-md font-bold text-on-surface-elevation-2 leading-sm">
                {{
                  $t(
                    'studio.prj_prod.this_prod.product_data.build.details_select_file_pop_total_file'
                  )
                }}
              </span>
              <span class="text-sm leading-xl text-on-surface-elevation-4">
                <span class="text-on-surface-elevation-2 font-bold">{{ selectedCount }}</span>
                / {{ totalElements }}
              </span>
            </div>
            <div class="flex-1 relative z-[602]">
              <s-dropdown
                v-model="integrityStore.selectedIntegrityType"
                size="sm"
                variant="line"
                :distance="0"
                :offset="[0, 0]"
                :closeOnClick="true"
                :interactive="true"
                appendTo="parent"
                placement="bottom-start"
                class="w-full"
                @update:modelValue="handleIntegrityTypeChange"
              >
                <s-dropdown-button class="w-full">
                  {{ $t(INTEGRITY_TYPE_OPTIONS[integrityStore.selectedIntegrityType]) }}
                </s-dropdown-button>
                <template #dropdownItems>
                  <s-dropdown-item
                    v-for="(text, value) in INTEGRITY_TYPE_OPTIONS"
                    :key="value"
                    :value="+value"
                  >
                    <s-tooltip
                      arrow
                      :content="$t(text)"
                      :duration="0"
                      :distance="4"
                      useFlip
                      flipOnUpdate
                      placement="bottom-start"
                      trigger="mouseenter focus"
                      :theme="'studio-tooltip'"
                      :zIndex="2501"
                      :allowHTML="true"
                      :class="'truncate'"
                    >
                      <template #target>
                        {{ $t(text) }}
                      </template>
                    </s-tooltip>
                  </s-dropdown-item>
                </template>
              </s-dropdown>
            </div>
          </div>
          <div
            ref="parentRef"
            class="relative flex-1 studio-scrollbar-4 pl-28 pr-8 min-h-100"
            :style="{ maxHeight: getMaxHeight }"
            @scroll="onScroll"
          >
            <m-table>
              <colgroup>
                <col style="width: 4.8rem" />
                <col style="width: auto" />
                <col style="width: 14.5rem" />
              </colgroup>
              <thead>
                <tr>
                  <st-th
                    v-model:checkBoxValue="checkAll"
                    checkboxUse
                    class="sticky top-0 z-[601]"
                    @onCheckboxUpdate="toggleSelectAll"
                  />
                  <st-th
                    :sortingUse="!isInteracted"
                    sortingDefault
                    :thTitle="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_select_file_pop_filename_header2'
                      )
                    "
                    class="sticky top-0 z-[601]"
                    :tooltipUse="true"
                    :sortValue="integrityStore.getSortValue(SORT_BY_INTEGRITY.RELATIVE_PATH)"
                    @onSort="(value) => handleSort(SORT_BY_INTEGRITY.RELATIVE_PATH, value)"
                  />
                  <st-th
                    :sortingUse="!isInteracted"
                    sortingDefault
                    :thTitle="
                      $t(
                        'studio.prj_prod.this_prod.product_data.build.details_integrity_check_select_file_pop_test_type_header3'
                      )
                    "
                    class="sticky top-0 z-[601]"
                    :tooltipUse="true"
                    :sortValue="integrityStore.getSortValue(SORT_BY_INTEGRITY.INTEGRITY_TYPE)"
                    @onSort="(value) => handleSort(SORT_BY_INTEGRITY.INTEGRITY_TYPE, value)"
                  />
                </tr>
              </thead>
              <tbody :style="{ height: `${totalHeight}px`, position: 'relative' }">
                <tr
                  v-for="virtualRow in virtualItems"
                  :key="localIntegrityFiles[virtualRow.index].relativePath"
                  class="virtual-row border-b-1 border-solid border-disabled-variant-3"
                  :style="{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    transform: `translateY(${virtualRow.index * ITEM_HEIGHT}px)`,
                    display: 'table',
                    tableLayout: 'fixed',
                    width: '100%'
                  }"
                >
                  <st-td aCenter class="!py-[.6rem]">
                    <checkbox
                      :id="`integrity-file-${virtualRow.index}`"
                      :modelValue="localIntegrityFiles[virtualRow.index].checked"
                      :options="{
                        size: 'sm',
                        align: 'middle',
                        class: 'h-16 w-16'
                      }"
                      @update:modelValue="
                        (value) =>
                          updateCheckAll(localIntegrityFiles[virtualRow.index].relativePath, value)
                      "
                    />
                  </st-td>
                  <st-conditional-tooltip-td
                    aLeft
                    :tdValue="localIntegrityFiles[virtualRow.index].relativePath"
                    class="!py-[.5rem] cursor-pointer"
                    textClass="text-sm truncate block"
                    @click="() => handleCellClick(localIntegrityFiles[virtualRow.index])"
                  />
                  <st-td aLeft class="!py-[.6rem]">
                    <integrity-status-cell
                      :status="localIntegrityFiles[virtualRow.index].integrityType"
                    />
                  </st-td>
                </tr>
              </tbody>
            </m-table>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="shrink-0">
        <s-button class="sm:min-w-264" @click="handleSave">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
  <loading :show="isSelectAllLoading" :isFullPage="true" :hasBackdrop="false" />
</template>
<script lang="ts" setup>
import { useVirtualizer } from '@tanstack/vue-virtual';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';

import Loading from '@/components/app/loading.vue';
import IntegrityStatusCell from '@/components/build/build-detail/integrity-status-cell.vue';
import StConditionalTooltipTd from '@/components/common/st-conditional-tooltip-td.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTh from '@/components/common/table/st-th.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { INTEGRITY_TYPE_OPTIONS, SORT_BY_INTEGRITY } from '@/constants/build.const';
import { useIntegrityStore } from '@/stores/integrity-file.store';
import type {
  IntegrityFile,
  IntegrityFileParams,
  TempSelectedFile
} from '@/types/build/build.type';

const props = defineProps<{
  isOpen: boolean;
  buildId: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

const onClose = async () => {
  try {
    isSelectAllLoading.value = true;
    await nextTick();
    await new Promise<void>((resolve: () => void) => setTimeout(resolve, 100));

    if (!hasSavedBefore.value) {
      resetState();
    }

    checkAll.value = false;
    tempSelectedIds.value = integrityStore.selectedIntegrityFileIds.map((file: IntegrityFile) => ({
      relativePath: file.relativePath,
      integrityType: file.integrityType
    }));
  } finally {
    setTimeout(() => {
      isSelectAllLoading.value = false;
      emit('close');
    }, 100);
  }
};

const integrityStore = useIntegrityStore();
const tempSelectedIds = ref<TempSelectedFile[]>([]);
const totalElements = computed(() => integrityStore.totalElements);
const checkAll = ref(false);
const isSelectCheckALL = ref(false);
const windowHeight = ref(window.innerHeight);
const isInteracted = ref(false);
const hasSavedBefore = ref(false);
const isSelectAllLoading = ref(false);

watch(
  () => props.isOpen,
  async (newValue: boolean) => {
    if (newValue) {
      try {
        isSelectAllLoading.value = true;
        await nextTick();
        await new Promise<void>((resolve: () => void) => setTimeout(resolve, 100));

        tempSelectedIds.value = integrityStore.selectedIntegrityFileIds.map(
          (file: IntegrityFile) => ({
            relativePath: file.relativePath,
            integrityType: file.integrityType
          })
        );

        checkAll.value = tempSelectedIds.value.length === integrityStore.listIntegrityFiles.length;
      } finally {
        setTimeout(() => {
          isSelectAllLoading.value = false;
        }, 100);
      }
    }
  }
);

const selectedCount = computed(() => {
  return localIntegrityFiles.value.filter((file: IntegrityFile) => file.checked).length;
});

const localIntegrityFiles = computed(() =>
  integrityStore.listIntegrityFiles.map((file: IntegrityFile) => {
    const selectedFile = tempSelectedIds.value.find(
      (item: IntegrityFile) => item.relativePath === file.relativePath
    );
    return {
      ...file,
      checked: !!selectedFile,
      integrityType: selectedFile ? selectedFile.integrityType : 0
    };
  })
);

const toggleSelectAll = async (newValue: boolean) => {
  if (isSelectAllLoading.value) {
    return;
  }

  try {
    isSelectAllLoading.value = true;
    isInteracted.value = true;
    isSelectCheckALL.value = true;
    checkAll.value = newValue;

    if (newValue) {
      // check: select all files and update integrity type by dropdown
      await new Promise<void>((resolve: () => void) => setTimeout(resolve, 300));
      tempSelectedIds.value = localIntegrityFiles.value.map((file: IntegrityFile) => ({
        relativePath: file.relativePath,
        integrityType: integrityStore.selectedIntegrityType
      }));
    } else {
      // Clear all selected files when uncheck all
      tempSelectedIds.value = [];
    }
  } finally {
    setTimeout(() => {
      isSelectAllLoading.value = false;
    }, 100);
  }
};

watch(
  () => integrityStore.listIntegrityFiles,
  () => {
    if (checkAll.value && isSelectCheckALL.value) {
      // if is check all, set checked for all files
      tempSelectedIds.value = localIntegrityFiles.value.map((file: IntegrityFile) => ({
        relativePath: file.relativePath,
        integrityType: integrityStore.selectedIntegrityType
      }));
    }
  },
  { deep: true }
);

const updateCheckAll = async (relativePath: string, isChecked: boolean) => {
  if (isSelectAllLoading.value) {
    return;
  }

  try {
    isSelectAllLoading.value = true;
    await nextTick();
    await new Promise<void>((resolve: () => void) => setTimeout(resolve, 100));

    isInteracted.value = true;
    const fileIndex = localIntegrityFiles.value.findIndex(
      (file: IntegrityFile) => file.relativePath === relativePath
    );

    if (fileIndex !== -1) {
      if (isChecked) {
        // When checking a file
        tempSelectedIds.value.push({
          relativePath,
          integrityType: integrityStore.selectedIntegrityType
        });
      } else {
        // When unchecking a file
        tempSelectedIds.value = tempSelectedIds.value.filter(
          (item: TempSelectedFile) => item.relativePath !== relativePath
        );
      }

      // Update checkAll status
      if (tempSelectedIds.value.length === localIntegrityFiles.value.length) {
        checkAll.value = true;
      } else {
        checkAll.value = false;
      }
    }
  } finally {
    setTimeout(() => {
      isSelectAllLoading.value = false;
    }, 100);
  }
};

const handleSort = (field: string, value: number) => {
  isSelectCheckALL.value = false;
  const direction = integrityStore.convertSortValueToDirection(value);
  integrityStore.updateSort(field, direction);
  resetAndFetchData();
};

const resetAndFetchData = () => {
  integrityStore.resetIntegrityFiles();
  fetchListIntegrityFiles();
};

const fetchListIntegrityFiles = async () => {
  const params: IntegrityFileParams = {
    buildId: props.buildId
  };
  await integrityStore.fetchListIntegrityFiles(params);
};

const onLoadMore = async () => {
  if (!integrityStore.isLastPage) {
    integrityStore.currentPage++;
    await fetchListIntegrityFiles();
  }
};

const handleIntegrityTypeChange = (newValue: number) => {
  integrityStore.updateSelectedIntegrityType(newValue);
};

const handleSave = () => {
  hasSavedBefore.value = true;
  // get checked files and keep integrityType
  integrityStore.selectedIntegrityFileIds = localIntegrityFiles.value
    .filter((file: IntegrityFile) => file.checked)
    .map((file: IntegrityFile) => ({
      relativePath: file.relativePath,
      integrityType: file.integrityType
    }));

  onClose();
};

const getMaxHeight = computed(() => {
  return windowHeight.value >= 900 ? '54.75rem' : '47.75rem';
});

const handleResize = () => {
  windowHeight.value = window.innerHeight;
};

const handleCellClick = (file: IntegrityFile) => {
  if (isSelectAllLoading.value) {
    return;
  }

  isInteracted.value = true;
  const newCheckedState = !file.checked;
  updateCheckAll(file.relativePath, newCheckedState);
};

const ITEM_HEIGHT = 40;
const virtualItems = computed(() => virtualizer.value.getVirtualItems());
const parentRef = ref<HTMLElement | null>(null);
const virtualizer = useVirtualizer({
  get count() {
    return localIntegrityFiles.value.length;
  },
  getScrollElement: () => parentRef.value,
  estimateSize: () => ITEM_HEIGHT,
  overscan: 5
});

const totalHeight = computed(() => localIntegrityFiles.value.length * ITEM_HEIGHT);

const onScroll = (e: Event) => {
  const target = e.target as HTMLElement;

  if (
    !integrityStore.isLastPage &&
    target.scrollTop + target.clientHeight >= localIntegrityFiles.value.length * ITEM_HEIGHT - 100
  ) {
    onLoadMore();
  }
};

// Scroll to top when data changes
watch(
  () => [integrityStore.sortField, integrityStore.sortDirection],
  () => {
    if (parentRef.value) {
      parentRef.value.scrollTop = 0;
    }
  }
);

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

const resetState = () => {
  isInteracted.value = false;
  isSelectCheckALL.value = false;
  hasSavedBefore.value = false;
};

defineExpose({
  resetState
});
</script>
<style scoped>
:deep(.stds-dropdown-list-item) {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.m-table {
  position: relative;
  width: 100%;
  table-layout: fixed;
}

.virtual-row {
  table-layout: fixed;
  width: 100%;
}

.virtual-row :deep(td:nth-child(2)) {
  width: auto;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.virtual-row :deep(td:first-child) {
  width: 4.8rem;
}

.virtual-row :deep(td:last-child) {
  width: 14.5rem;
}
</style>
