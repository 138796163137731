<template>
  <div class="flex flex-col gap-4 items-start">
    <p class="text-md font-bold leading-sm text-on-surface-elevation-2">
      {{ buildStatusMessage }}
    </p>
    <p
      v-if="statusSecurity === UPLOAD_STATUS_MESSAGE.SUCCESS"
      class="text-md font-regular leading-lg text-on-surface-elevation-2"
    >
      {{ $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_success_msg') }}
    </p>
    <p
      v-if="
        statusSecurity === UPLOAD_STATUS_MESSAGE.READY ||
          statusSecurity === UPLOAD_STATUS_MESSAGE.START
      "
      class="text-md font-regular leading-lg text-on-surface-elevation-2"
    >
      {{ $t(buildProgressMessage) }}
      <span class="text-xs font-medium text-brand-primary">
        {{
          $t('studio.build_mgmt.build_steps_count', {
            currentStep: buildProgress.displayStep,
            totalStep: buildProgress.totalSteps
          })
        }}
      </span>
    </p>
    <template v-if="statusSecurity === UPLOAD_STATUS_MESSAGE.FAIL">
      <safe-html
        tag="p"
        class="text-md font-regular leading-lg text-on-surface-elevation-2"
        :html="$t(errorMessage, errorMessageVariables)"
      />
      <span
        v-if="errorDetailKeys.length > 0"
        class="text-md font-regular leading-lg text-on-surface-elevation-2"
      >
        { {{ errorDetailKeys.join(', ') }} }
      </span>
      <span class="text-xs font-medium leading-xs text-system-red">[{{ errorCodeSecurity }}]</span>
    </template>
    <span
      v-if="
        statusSecurity === UPLOAD_STATUS_MESSAGE.FAIL &&
          reviewStatus === UPLOAD_STATUS_MESSAGE.REQUEST
      "
      class="rounded-[.6rem] bg-[#F0F2F8] inline-flex h-24 text-xs leading-xs items-center px-[.6rem] text-[#909298] font-regular"
    >
      {{
        $t(
          'studio.prj_prod.this_prod.product_data.build.security_test_failed_manual_test_requested_stt'
        )
      }}
    </span>
    <span
      v-if="
        statusSecurity === UPLOAD_STATUS_MESSAGE.FAIL &&
          reviewStatus === UPLOAD_STATUS_MESSAGE.REJECTED
      "
      class="rounded-[.6rem] bg-[#FFEDED] inline-flex h-24 text-xs leading-xs items-center px-[.6rem] text-[#E84C4C] font-regular"
    >
      {{
        $t(
          'studio.prj_prod.this_prod.product_data.build.details_basic_info_upload_status_request_manual_test_rejected_stt'
        )
      }}
    </span>
    <p
      v-if="
        statusSecurity === UPLOAD_STATUS_MESSAGE.FAIL &&
          reviewStatus === UPLOAD_STATUS_MESSAGE.REJECTED &&
          reasonMessage
      "
      class="text-sm leading-md text-on-surface-elevation-2 font-regular break-words break-all overflow-hidden whitespace-normal max-w-full"
    >
      {{ reasonMessage }}
    </p>
  </div>

  <s-button
    v-if="
      statusSecurity === UPLOAD_STATUS_MESSAGE.FAIL &&
        isSecurityInspectionFailError &&
        !reviewStatus
    "
    variant="secondary"
    size="sm"
    class="w-fit -mt-8"
    :disabled="!canOpenSecurityManual"
    @click="openSecurityManual"
  >
    {{
      $t(
        'studio.prj_prod.this_prod.product_data.build.security_test_failed_manual_test_request_btn'
      )
    }}
  </s-button>

  <security-manual
    v-if="showSecurityManual"
    :errorDetailSecurity="props.selectedBuild.vaccine?.errorDetail"
    :buildId="props.selectedBuild.buildId"
    :gameId="props.selectedBuild.gameId"
    :errorCodeSecurity="props.selectedBuild.vaccine?.errorCode"
    @close="showSecurityManual = false"
    @submitSuccess="$emit('refresh')"
  />
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SecurityManual from '@/components/build/list-table/security-manual.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import {
  ARRAY_ERROR_CODE_SECURITY_INSPECTION_FAIL,
  UPLOAD_STATUS_MESSAGE
} from '@/constants/build.const';
import useProductStore from '@/stores/product.store';
import type { Build } from '@/types/build/build.type';
import {
  getErrorMessage,
  getErrorMessageVariables,
  renderValueMessageSecurity
} from '@/utils/build.util';

const props = defineProps<{
  selectedBuild: Build;
}>();

defineEmits(['refresh']);

const { t } = useI18n();
const showSecurityManual = ref(false);
const productStore = useProductStore();
const { isProductWritable } = storeToRefs(productStore);

const statusSecurity = computed(() => props.selectedBuild.vaccine?.status);
const reviewStatus = computed(() => props.selectedBuild.reviewStatus);
const errorCodeSecurity = computed(() => props.selectedBuild.vaccine?.errorCode);
const errorMessage = computed(() => {
  return getErrorMessage(
    errorCodeSecurity.value,
    'studio.build_mgmt.case_drm_maker.unknown_error_error_msg'
  );
});
const errorMessageVariables = computed(() => {
  return getErrorMessageVariables(errorCodeSecurity.value);
});
const errorDetailKeys = computed(() => {
  if (props.selectedBuild.vaccine?.errorDetail) {
    return Object.keys(props.selectedBuild.vaccine.errorDetail);
  }
  return [];
});
const reasonMessage = computed(() => props.selectedBuild.memo || '');
const currentStepSecurity = computed(() => props.selectedBuild.vaccine?.currentStep);
const isSecurityInspectionFailError = computed(() => {
  return ARRAY_ERROR_CODE_SECURITY_INSPECTION_FAIL.includes(
    props.selectedBuild?.vaccine?.errorCode?.toString() || ''
  );
});

const buildStatusMessage = computed(() => {
  switch (statusSecurity.value) {
    case UPLOAD_STATUS_MESSAGE.FAIL:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_failed_msg'
      )}]`;
    case UPLOAD_STATUS_MESSAGE.SUCCESS:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_inspection_success_title'
      )}]`;
    case UPLOAD_STATUS_MESSAGE.READY:
    case UPLOAD_STATUS_MESSAGE.START:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_msg'
      )}]`;
    default:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_security_test_msg'
      )}]`;
  }
});

const canOpenSecurityManual = computed(() => {
  return isProductWritable.value;
});

const openSecurityManual = () => {
  if (!canOpenSecurityManual.value) {
    return;
  }

  showSecurityManual.value = true;
};

const buildProgress = computed(() => renderValueMessageSecurity(currentStepSecurity.value));
const buildProgressMessage = computed(() => buildProgress.value.message);
</script>
