import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getIntegrityFileListsApi } from '@/apis/build.api';
import { SORT_BY_INTEGRITY, VALUE_INTEGRITY_TYPE } from '@/constants/build.const';
import { SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import type {
  IntegrityFile,
  IntegrityFilePagination,
  IntegrityFileParams
} from '@/types/build/build.type';

export const useIntegrityStore = defineStore('integrityFileStore', () => {
  const listIntegrityFiles = ref<IntegrityFile[]>([]);
  const totalElements = ref<number>(0);
  const currentPage = ref<number>(1);
  const sortField = ref<string>(SORT_BY_INTEGRITY.RELATIVE_PATH);
  const sortDirection = ref<string>('');
  const isFirstPage = ref<boolean>(true);
  const isLastPage = ref<boolean>(false);
  const totalPages = ref<number>(0);
  const originalIntegrityFiles = ref<IntegrityFile[]>([]);
  const selectedIntegrityFileIds = ref<IntegrityFile[]>([]);
  const selectedIntegrityType = ref<number>(VALUE_INTEGRITY_TYPE.EXIST_HASH);

  const fetchListIntegrityFiles = async (params: IntegrityFileParams) => {
    const response = await getIntegrityFileListsApi({
      ...params,
      page: currentPage.value,
      sort: sortField.value,
      direction: sortDirection.value
    });

    if (response) {
      const data: IntegrityFilePagination = response;
      if (data && Array.isArray(data.contents)) {
        const processedContents = data.contents.map((file: IntegrityFile) => ({
          ...file,
          integrityType: Number(file.integrityType)
        }));

        if (data.first) {
          listIntegrityFiles.value = processedContents;
        } else {
          listIntegrityFiles.value = [...listIntegrityFiles.value, ...processedContents];
        }

        totalElements.value = data.totalElements;
        totalPages.value = data.totalPages;
        currentPage.value = data.page;
        isFirstPage.value = data.first;
        isLastPage.value = data.last;
      }
    }
  };

  const resetIntegrityFiles = () => {
    listIntegrityFiles.value = [];
    totalElements.value = 0;
    totalPages.value = 0;
    currentPage.value = 1;
    isFirstPage.value = true;
    isLastPage.value = false;
  };

  const setOriginalIntegrityFiles = (originalIntegrityRelativeFiles: IntegrityFile[]) => {
    originalIntegrityFiles.value = originalIntegrityRelativeFiles;
    selectedIntegrityFileIds.value = [...originalIntegrityRelativeFiles];
  };

  const getSortValue = (field: string): number => {
    if (sortField.value !== field) {
      return SORT_VALUE.NONE;
    }
    return sortDirection.value === SORT_DIRECTION.DESC ? SORT_VALUE.ASC : SORT_VALUE.DESC;
  };

  const updateSort = (field: string, direction: string) => {
    sortField.value = field;
    sortDirection.value = direction;
  };

  const convertSortValueToDirection = (value: number): string => {
    switch (value) {
      case SORT_VALUE.ASC:
        return SORT_DIRECTION.DESC;
      case SORT_VALUE.DESC:
        return SORT_DIRECTION.ASC;
      default:
        return SORT_DIRECTION.DESC;
    }
  };

  const updateSelectedIntegrityType = (type: number) => {
    selectedIntegrityType.value = type;
  };

  return {
    listIntegrityFiles,
    totalElements,
    currentPage,
    sortField,
    sortDirection,
    isFirstPage,
    isLastPage,
    originalIntegrityFiles,
    selectedIntegrityFileIds,
    selectedIntegrityType,
    fetchListIntegrityFiles,
    resetIntegrityFiles,
    setOriginalIntegrityFiles,
    getSortValue,
    updateSort,
    convertSortValueToDirection,
    updateSelectedIntegrityType
  };
});
