<template>
  <!-- UPLOAD FAILED -->
  <template v-if="uploadStatus === UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED">
    <safe-html
      tag="p"
      class="text-md font-regular leading-lg text-on-surface-elevation-2"
      :html="$t(errorMessage)"
    />
    <span class="text-xs font-medium leading-xs text-system-red">[{{ errorCodeBuildFailed }}]</span>
  </template>

  <!-- UPLOAD STOPPED -->
  <template v-if="uploadStatus === UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED">
    <safe-html
      tag="p"
      class="text-sm leading-md text-on-surface-elevation-4 font-regular flex-1 mt-4"
      :html="
        $t(
          isPermissionDelete(memberNo, userInfo?.memberNo)
            ? 'studio.prj_prod.this_prod.product_data.build.details_basic_info_upload_status1_paused_guide'
            : 'studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop2_msg2'
        )
      "
    />
    <div class="shrink-0 mt-8">
      <s-button
        variant="outline"
        size="sm"
        :disabled="
          !canContinue ||
            uploadStatus !== UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED ||
            !isPermissionDelete(memberNo, userInfo?.memberNo)
        "
        @click="onContinue"
      >
        {{
          $t(
            'studio.prj_prod.this_prod.product_data.build.details_basic_info_upload_status_resume_btn'
          )
        }}
      </s-button>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import SafeHtml from '@/components/common/safe-html.vue';
import { useApp } from '@/composables/useApp';
import { useDownloadUploaderDialog } from '@/composables/useDownloadUploaderDialog';
import { UPLOAD_STATUS_MESSAGE } from '@/constants/build.const';
import { useAppStore } from '@/stores/app.store';
import useProductStore from '@/stores/product.store';
import { useUserStore } from '@/stores/user.store';
import type { Build } from '@/types/build/build.type';
import { getErrorMessage, handleUploadBuild, isPermissionDelete } from '@/utils/build.util';

const props = defineProps<{
  uploadStatus: string;
  memberNo: number;
  selectedBuild: Build;
}>();

const { checkProductPermission } = useApp();

const { locale } = useI18n();
const route = useRoute();

const userStore = useUserStore();
const { gdsInfo } = storeToRefs(useAppStore());
const productStore = useProductStore();

const { accessToken, selectedGroupId, userInfo } = storeToRefs(userStore);
const { product, isProductWritable } = storeToRefs(productStore);

const { setupVisibilityListeners } = useDownloadUploaderDialog();

setupVisibilityListeners();

const canContinue = computed(() => {
  return isProductWritable.value;
});

const gameId = product.value?.gameId || '';
const buildId = route.params.buildId as string;

const errorCodeBuildFailed = computed(() => props.selectedBuild.errorCode);
const errorMessage = computed(() => {
  return getErrorMessage(errorCodeBuildFailed.value, '');
});

const onContinue = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  if (!canContinue.value) {
    return;
  }

  if (!selectedGroupId.value && gameId) {
    return;
  }

  handleUploadBuild({
    selectedGroupId: selectedGroupId.value,
    accessToken: accessToken.value,
    gameId,
    langCd: locale.value,
    buildId,
    nationCd: gdsInfo.value.nation
  });
};
</script>
