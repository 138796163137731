import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { getDRMFileListsApi } from '@/apis/build.api';
import {
  APPLICATION_STATUS,
  SORT_BY_MAKER,
  TYPE_STATUS_DRM_SECURITY
} from '@/constants/build.const';
import { SORT_DIRECTION, SORT_VALUE } from '@/constants/common.const';
import type {
  DRMFile,
  DRMFilePagination,
  DRMFileParams,
  StatusCounts,
  StatusGroups
} from '@/types/build/build.type';

export const useDRMStore = defineStore('drmFileStore', () => {
  const drmFiles = ref<DRMFile[]>([]);
  const totalElements = ref<number>(0);
  const totalPages = ref<number>(0);
  const currentPage = ref<number>(0);
  const selectedDrmFileIds = ref<string[]>([]);
  const isFirstPage = ref<boolean>(true);
  const isLastPage = ref<boolean>(false);
  const sortField = ref<string>(SORT_BY_MAKER.RELATIVE_PATH);
  const sortDirection = ref<string>(SORT_DIRECTION.DESC);
  const originalDrmFiles = ref<string[]>([]);
  const isSelectAll = ref<boolean>(false);
  const originalSecurityLevels = ref<Record<string, string>>({});
  const popupDrmFiles = ref<DRMFile[]>([]);

  const fetchDRMFiles = async (params: DRMFileParams) => {
    try {
      const response = await getDRMFileListsApi({
        ...params,
        page: currentPage.value,
        sort: sortField.value,
        direction: sortDirection.value
      });

      if (response?.contents) {
        const data: DRMFilePagination = response;

        if (Array.isArray(data.contents) && data.contents.length > 0) {
          if (isSelectAll.value) {
            const newFilePaths = data.contents
              .map((file: DRMFile) => file.relativePath)
              .filter((path: string) => !selectedDrmFileIds.value.includes(path));
            selectedDrmFileIds.value.push(...newFilePaths);
          }

          if (data.first) {
            drmFiles.value = data.contents;
          } else {
            drmFiles.value = [...drmFiles.value, ...data.contents];
          }

          popupDrmFiles.value = [...drmFiles.value];

          totalElements.value = data.totalElements;
          totalPages.value = data.totalPages;
          currentPage.value = data.page;
          isFirstPage.value = data.first;
          isLastPage.value = data.last;

          if (drmFiles.value.length > 0) {
            setOriginalSecurityLevels(drmFiles.value);
          }

          return true;
        }
      }
      return false;
    } catch (error) {
      // console.error('Error fetching DRM files:', error);
      return false;
    }
  };

  const resetDRMFiles = () => {
    drmFiles.value = [];
    resetPopupFiles();
    totalElements.value = 0;
    totalPages.value = 0;
    currentPage.value = 1;
    isFirstPage.value = true;
    isLastPage.value = false;
  };

  const getSortValue = (field: string): number => {
    if (sortField.value !== field) {
      return SORT_VALUE.NONE;
    }
    return sortDirection.value === SORT_DIRECTION.DESC ? SORT_VALUE.ASC : SORT_VALUE.DESC;
  };

  const updateSort = (field: string, direction: string) => {
    sortField.value = field;
    sortDirection.value = direction;
  };

  const convertSortValueToDirection = (value: number): string => {
    switch (value) {
      case SORT_VALUE.ASC:
        return SORT_DIRECTION.DESC;
      case SORT_VALUE.DESC:
        return SORT_DIRECTION.ASC;
      default:
        return SORT_DIRECTION.DESC;
    }
  };

  const updateSelectedDrmFileIds = (relativePaths: string[]) => {
    selectedDrmFileIds.value = [...relativePaths];
  };

  const resetStore = () => {
    drmFiles.value = [];
    totalElements.value = 0;
    totalPages.value = 0;
    currentPage.value = 1;
    selectedDrmFileIds.value = [];
    isFirstPage.value = true;
    isLastPage.value = false;
    resetSort();
    isSelectAll.value = false;
  };

  const resetSort = () => {
    sortField.value = SORT_BY_MAKER.RELATIVE_PATH;
    sortDirection.value = SORT_DIRECTION.DESC;
  };

  const selectedDrmFilesByStatus = computed<StatusGroups>(() => {
    const statusGroups = {
      [APPLICATION_STATUS.REQUESTED]: [],
      [APPLICATION_STATUS.SUCCESS]: [],
      [APPLICATION_STATUS.FAILED]: [],
      [APPLICATION_STATUS.REQUESTED_DELETE]: [],
      [APPLICATION_STATUS.NULL]: []
    };

    selectedDrmFileIds.value.forEach((relativePath: string) => {
      const file = drmFiles.value.find((item: DRMFile) => item.relativePath === relativePath);
      if (file) {
        const status = file.applicationStatus === null ? 'NULL' : file.applicationStatus;
        (statusGroups[status] as DRMFile[]).push(file);
      }
    });

    return statusGroups;
  });

  const selectedDrmFilesCounts = computed<StatusCounts>(() => {
    return Object.keys(selectedDrmFilesByStatus.value).reduce<StatusCounts>(
      (acc: StatusCounts, status: string) => {
        if (status in APPLICATION_STATUS || status === 'NULL') {
          acc[status as keyof StatusGroups] =
            selectedDrmFilesByStatus.value[status as keyof StatusGroups].length;
        }
        return acc;
      },
      {}
    );
  });

  const setOriginalDrmFiles = (originalDRMRelativeFiles: string[]) => {
    selectedDrmFileIds.value = [...originalDRMRelativeFiles];
    originalDrmFiles.value = originalDRMRelativeFiles;
  };

  const setOriginalSecurityLevels = (files: DRMFile[]) => {
    originalSecurityLevels.value = files.reduce((acc: Record<string, string>, file: DRMFile) => {
      acc[file.relativePath] = file.securityLevel || TYPE_STATUS_DRM_SECURITY.NONE;
      return acc;
    }, {} as Record<string, string>);
  };

  const resetSecurityLevels = () => {
    popupDrmFiles.value = drmFiles.value.map((file: DRMFile) => ({ ...file }));
  };

  const initPopupFiles = () => {
    popupDrmFiles.value = drmFiles.value.map((file: DRMFile) => ({ ...file }));
  };

  const applyPopupChanges = () => {
    drmFiles.value = popupDrmFiles.value.map((file: DRMFile) => ({ ...file }));
  };

  const updatePopupFiles = () => {
    popupDrmFiles.value = [...drmFiles.value];
  };

  const resetPopupFiles = () => {
    popupDrmFiles.value = [];
  };

  return {
    drmFiles,
    totalElements,
    totalPages,
    currentPage,
    selectedDrmFileIds,
    isFirstPage,
    isLastPage,
    sortField,
    sortDirection,
    updateSort,
    fetchDRMFiles,
    updateSelectedDrmFileIds,
    resetDRMFiles,
    getSortValue,
    convertSortValueToDirection,
    resetStore,
    resetSort,
    selectedDrmFilesByStatus,
    selectedDrmFilesCounts,
    originalDrmFiles,
    setOriginalDrmFiles,
    isSelectAll,
    originalSecurityLevels,
    setOriginalSecurityLevels,
    resetSecurityLevels,
    popupDrmFiles,
    updatePopupFiles,
    resetPopupFiles,
    initPopupFiles,
    applyPopupChanges
  };
});
