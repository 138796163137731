<template>
  <div class="flex flex-col gap-4 items-start">
    <p class="text-md font-bold leading-sm text-on-surface-elevation-2">
      {{ buildStatusMessage }}
    </p>
    <template v-if="statusCreation === UPLOAD_STATUS_MESSAGE.FAIL">
      <safe-html
        tag="p"
        class="text-md font-regular leading-lg text-on-surface-elevation-2"
        :html="$t(errorMessage)"
      />
      <span class="text-xs font-medium leading-xs text-system-red">[{{ errorCodeCreation }}]</span>
    </template>
    <p
      v-else-if="statusCreation === UPLOAD_STATUS_MESSAGE.SUCCESS"
      class="text-md font-regular leading-lg text-on-surface-elevation-2"
    >
      {{ $t('studio.prj_prod.this_prod.product_data.build.home_upload_status_success_msg') }}
    </p>
    <p v-else class="text-md font-regular leading-lg text-on-surface-elevation-2">
      {{ $t(buildProgressMessage) }}
      <span class="text-xs font-medium text-brand-primary">
        {{
          $t('studio.build_mgmt.build_steps_count', {
            currentStep: buildProgress.displayStep,
            totalStep: buildProgress.totalSteps
          })
        }}
      </span>
    </p>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import { UPLOAD_STATUS_MESSAGE, VARIABLE_CODE_LANG } from '@/constants/build.const';
import type { Build } from '@/types/build/build.type';
import { renderValueMessageBuild } from '@/utils/build.util';

const props = defineProps<{
  selectedBuild: Build;
}>();

const { t } = useI18n();

const statusCreation = computed(() => props.selectedBuild.build?.status);
const errorCodeCreation = computed(() => props.selectedBuild.build?.errorCode);
const errorMessage = computed(() => {
  if (errorCodeCreation.value && errorCodeCreation.value in VARIABLE_CODE_LANG) {
    return VARIABLE_CODE_LANG[errorCodeCreation.value];
  }
  return '';
});
const currentStepCreation = computed(() => props.selectedBuild.build?.currentStep);

const buildStatusMessage = computed(() => {
  switch (statusCreation.value) {
    case UPLOAD_STATUS_MESSAGE.FAIL:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_failed_msg'
      )}]`;
    case UPLOAD_STATUS_MESSAGE.SUCCESS:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_success_title'
      )}]`;
    case UPLOAD_STATUS_MESSAGE.READY:
    case UPLOAD_STATUS_MESSAGE.START:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_creating_msg'
      )}]`;
    default:
      return `[${t(
        'studio.prj_prod.this_prod.product_data.build.home_upload_status_testing_pop_build_status_creating_msg'
      )}]`;
  }
});

const buildProgress = computed(() => renderValueMessageBuild(currentStepCreation.value));
const buildProgressMessage = computed(() => buildProgress.value.message);
</script>
