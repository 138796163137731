<template>
  <st-box class="mt-24">
    <div class="flex items-center gap-8">
      <h3 class="flex-1 text-2xl leading-xl break-all font-bold text-on-surface-elevation-2">
        {{ $t('studio.prj_prod.this_prod.product_data.build.details_integrity_check_title') }}
      </h3>
    </div>
    <div class="mt-8">
      <safe-html
        tag="p"
        class="text-sm font-regular leading-md text-on-surface-elevation-3"
        :html="$t('studio.prj_prod.this_prod.product_data.build.details_integrity_check_msg1')"
      />
      <ul class="text-sm leading-md text-on-surface-elevation-3">
        <li
          class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.product_data.build.details_integrity_check_msg2') }}
        </li>
      </ul>
    </div>
    <st-form-title
      :formTitle="
        $t('studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_msg')
      "
      class="mt-16"
    />
    <div class="flex gap-16 items-center">
      <div class="w-[38rem]">
        <input-text
          v-model="mIntegrityFiles"
          variant="fill"
          :placeholder="
            $t(
              'studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_place_holder'
            )
          "
          :clearable="false"
          :readonly="true"
          :disabled="true"
        />
      </div>
      <s-button
        variant="secondary"
        size="sm"
        class="shrink-0"
        :disabled="!canEditIntegrity"
        @click="openFileDialog"
      >
        {{
          $t(
            'studio.prj_prod.this_prod.product_data.build.details_integrity_check_files_select_file_btn'
          )
        }}
      </s-button>
    </div>
    <s-button
      variant="primary"
      size="md"
      class="min-w-[11.6rem] mt-24"
      :disabled="isButtonDisabled"
      @click="onSaveIntegrity"
    >
      {{ $t('studio.common.popup_case_save_btn') }}
    </s-button>
  </st-box>
  <integrity-file-popup
    ref="integrityFilePopupRef"
    :isOpen="isFileDialogOpen"
    :buildId="props.buildId"
    @close="closeFileDialog"
  />
  <loading :show="isCheckingProgress" :isFullPage="true" :hasBackdrop="true" />
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { checkProgressIntegrityApi, updateBuildIntegrityFilesApi } from '@/apis/build.api';
import Loading from '@/components/app/loading.vue';
import IntegrityFilePopup from '@/components/build/build-detail/integrity-file-popup.vue';
import ConfirmReleaseBuild from '@/components/build/dialog/confirm-release-build.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { useProduct } from '@/composables/useProduct';
import { DATA_VERSION, RELEASE_STATUS_BUILD } from '@/constants/build.const';
import { ProductVerifyStatus } from '@/enums/common.enum';
import { useBuildStore } from '@/stores/build.store';
import { useIntegrityStore } from '@/stores/integrity-file.store';
import useProductStore from '@/stores/product.store';
import type {
  CheckProgressIntegrityResponse,
  IntegrityFile,
  IntegrityFileProps,
  UpdateBuildIntegrityParams
} from '@/types/build/build.type';

const props = defineProps<{
  buildId: string;
  integrityFiles: IntegrityFileProps;
  releaseYn: string;
  releaseStatus: string | null;
  dataVersion: number;
}>();

const { t } = useI18n();
const buildStore = useBuildStore();
const { buildDetail } = storeToRefs(buildStore);

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

const productStore = useProductStore();
const { product, isProductWritable } = storeToRefs(productStore);

const dataVersion = computed(() => props.dataVersion);

const canEditIntegrity = computed(() => {
  return isProductWritable.value && dataVersion.value !== DATA_VERSION.OLD;
});

const integrityStore = useIntegrityStore();
integrityStore.fetchListIntegrityFiles({
  buildId: props.buildId
});

const isFileDialogOpen = ref(false);
const mIntegrityFiles = ref('');
const integrityFilePopupRef = ref();
const isCheckingProgress = ref(false);
const timeInterval = ref(2000);

const convertText = (files: IntegrityFile[]) => {
  if (files.length === 0) {
    return '';
  }
  const firstFile = files[0].relativePath;
  const othersCount = files.length - 1;
  return othersCount > 0
    ? t('studio.file_upload.count', {
      fileName: firstFile,
      remainingFileNum: othersCount
    })
    : firstFile;
};

const openFileDialog = () => {
  if (!canEditIntegrity.value) {
    return;
  }
  isFileDialogOpen.value = true;
};

const closeFileDialog = () => {
  isFileDialogOpen.value = false;
};

watch(
  [() => integrityStore.listIntegrityFiles, () => props.integrityFiles],
  ([newListFiles, newIntegrityFiles]: [IntegrityFile[], IntegrityFileProps | undefined]) => {
    if (newListFiles.length > 0 && newIntegrityFiles?.integrityFiles) {
      const integrityFileObjects = newListFiles
        .filter((file: IntegrityFile) =>
          newIntegrityFiles.integrityFiles.includes(file.relativePath)
        )
        .map((file: IntegrityFile) => ({
          relativePath: file.relativePath,
          integrityType: file.integrityType
        }));

      mIntegrityFiles.value = convertText(integrityFileObjects);
      integrityStore.setOriginalIntegrityFiles(integrityFileObjects);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => integrityStore.selectedIntegrityFileIds,
  (newSelectedFiles: IntegrityFile[]) => {
    mIntegrityFiles.value = convertText(newSelectedFiles);
  },
  { deep: true }
);

const hasChanges = computed(() => {
  const originalFiles = integrityStore.originalIntegrityFiles;
  const selectedFiles = integrityStore.selectedIntegrityFileIds;
  return !arraysEqual(originalFiles, selectedFiles);
});

function arraysEqual(originalFiles: IntegrityFile[], selectedFiles: IntegrityFile[]) {
  if (originalFiles.length !== selectedFiles.length) {
    return false;
  }
  return originalFiles.every(
    (val: IntegrityFile, i: number) =>
      val.relativePath === selectedFiles[i].relativePath &&
      val.integrityType === selectedFiles[i].integrityType
  );
}

const onSaveIntegrity = async () => {
  if (!canEditIntegrity.value) {
    return;
  }

  const cleanedFiles = integrityStore.selectedIntegrityFileIds.map(
    ({ id: _id, checked: _checked, ...rest }: IntegrityFile) => rest
  );

  const params = {
    buildId: props.buildId,
    integrityApplyFiles: cleanedFiles
  };

  await callAPISave(params);
};

const callAPISave = async (params: UpdateBuildIntegrityParams) => {
  if (
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.APPLY_LIVE
  ) {
    const confirm = await showDialog({
      component: shallowRef(ConfirmReleaseBuild)
    });

    if (!confirm) {
      return;
    }
  }

  const result = await updateBuildIntegrityFilesApi(params);

  if (result?.code === 0) {
    // Update originalIntegrityFiles with new selected files
    // integrityStore.setOriginalIntegrityFiles([...integrityStore.selectedIntegrityFileIds]);
    integrityFilePopupRef.value?.resetState();

    isCheckingProgress.value = true;

    const checkInterval = setInterval(async () => {
      const progressResult = await checkProgressIntegrityApi(props.buildId);

      if (progressResult?.isFinished) {
        clearInterval(checkInterval);
        isCheckingProgress.value = false;

        if (
          buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.COMING_SOON ||
          buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.APPLY_LIVE
        ) {
          const confirmDialogLive = await showAlert({
            severity: 'info',
            content: t('studio.common.popup_case_iv_release_guide'),
            confirmLabel: t('studio.common.popup_case_cf_btn')
          });
          if (confirmDialogLive) {
            await refreshIntegrityData();
          }
          return;
        }

        await refreshIntegrityData();
        if (!showCompleteSaveDialog()) {
          await refreshIntegrityData();
        }
      }
    }, timeInterval.value);
  }
};

const refreshIntegrityData = async () => {
  await integrityStore.fetchListIntegrityFiles({
    buildId: props.buildId
  });
  await buildStore.getBuildDetail({
    buildId: props.buildId
  });
};

const isButtonDisabled = computed(() => {
  if (dataVersion.value === DATA_VERSION.OLD) {
    return true;
  }

  if (product.value?.verifyStatus === ProductVerifyStatus.START ||
    product.value?.verifyStatus === ProductVerifyStatus.REQUEST) {
    return true;
  }

  if (buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW ||
    buildDetail.value?.releaseStatus === RELEASE_STATUS_BUILD.RELEASE_UNDER_REVIEW) {
    return true;
  }

  return !canEditIntegrity.value || !hasChanges.value;
});

onMounted(async () => {
  if (props.dataVersion !== DATA_VERSION.OLD) {
    const [, progressResult]: [void, CheckProgressIntegrityResponse | undefined] = await Promise.all([
      integrityStore.fetchListIntegrityFiles({
        buildId: props.buildId
      }),
      checkProgressIntegrityApi(props.buildId)
    ]);

    if (progressResult && !progressResult.isFinished) {
      isCheckingProgress.value = true;

      const checkInterval = setInterval(async () => {
        const result = await checkProgressIntegrityApi(props.buildId);

        if (result?.isFinished) {
          clearInterval(checkInterval);
          isCheckingProgress.value = false;
        }
      }, timeInterval.value);
    }
  }
});
</script>
